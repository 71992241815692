import React from 'react';

const FacebookIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 86.56 86.56"
            className="size-9"
        >
            <rect
                x="0"
                y="0"
                width="86.56"
                height="86.56"
                rx="9.8"
                ry="9.8"
                className="fill-customGray"
            />
            <path
                d="M47.76 69.06l0 -22.56 7.52 0 1.43 -9.33 -8.95 0 0 -6.05c0,-2.55 1.25,-5.04 5.26,-5.04l4.07 0 0 -7.94c0,0 -3.69,-0.63 -7.23,-0.63 -7.37,0 -12.19,4.47 -12.19,12.56l0 7.11 -8.2 0 0 9.33 8.2 0 0 22.56 10.09 0z"
                className="fill-white"
            />
        </svg>
    );
};

export default FacebookIcon;
