export const ImageModal = ({ isOpen, onClose, imageSrc }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-white bg-opacity-95 flex items-center justify-center z-50">
            <div className="max-w-3xl max-h-3xl">
                <img src={imageSrc} alt="" className="max-w-full max-h-full" />
                <button
                    onClick={onClose}
                    className="absolute top-4 right-4 text-black text-2xl"
                >
                    &times;
                </button>
            </div>
        </div>
    );
};