import React from 'react';

const Newest = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24.49 8.32"
        className="fill-current size-5"
    >
        <path
            className="fill-black"
            d="M21.71 6l-4.32 0.09 4.32 -0.09zm-13.22 1.83c0.64,0.64 1.34,0.43 2.46,0.43 1.18,0 1.93,0.21 2.46,-0.32 0.29,-0.29 0.2,-0.75 -0.02,-1 -0.45,-0.5 -2.14,-0.07 -3.14,-0.27l-0.02 -1.91c0.23,0.04 2.47,0.22 2.85,-0.26 0.29,-0.36 0.2,-0.74 -0.11,-1.05 -0.46,-0.46 -2.14,-0.13 -2.76,-0.26 0.09,-0.44 -0.11,-1.25 0.1,-1.61 1.09,-0.21 2.5,0.28 2.96,-0.31 0.26,-0.33 0.22,-0.73 -0.1,-1.05 -0.3,-0.3 -2.49,-0.18 -3.02,-0.18 -0.42,-0 -1.16,-0.11 -1.5,0.19 -0.42,0.38 -0.29,1.04 -0.29,1.49 -0,0.7 -0.12,5.85 0.11,6.08zm-3.31 -2.22l-3.46 -3.17 3.46 3.17zm0 0l-0.74 -1.36c-0.37,-0.7 -1.97,-3.85 -2.26,-4.07 -0.3,-0.23 -1.03,-0.21 -1.36,-0.15 -0.64,0.13 -0.79,0.46 -0.78,1.04l-0.01 5.43c-0,0.38 -0.11,1.03 0.17,1.36 0.39,0.46 1,0.45 1.34,0.05 0.3,-0.36 0.2,-0.94 0.2,-1.35l0.01 -2.71c-0.01,-0.37 -0.12,-0.95 -0.02,-1.41 0.18,0.18 0.6,1.03 0.74,1.31l2.16 4.08c0.35,0.54 2.12,0.85 2.2,-0.6 0.06,-1.17 0.01,-4.05 0.01,-5.43 0,-0.39 0.08,-1 -0.14,-1.36 -0.24,-0.39 -0.86,-0.53 -1.27,-0.2 -0.49,0.39 -0.37,2.07 -0.3,2.66 0.05,0.42 -0,0.91 0.01,1.36 0.01,0.32 0.12,1.11 0.07,1.36zm16.53 0.39l-0.89 -4.93c-0.18,-0.93 -0.54,-0.98 -1.26,-0.98 -1.12,-0.01 -1.06,0.61 -1.31,1.83l-0.86 4.17c-0.08,-0.25 -0.08,-0.39 -0.13,-0.65 -0.11,-0.59 -0.74,-4.58 -0.91,-4.88 -0.46,-0.81 -2.13,-0.67 -1.72,0.95l1.32 5.58c0.1,0.5 0.16,1 0.82,1.15 0.82,0.19 1.53,-0.02 1.7,-0.9l0.77 -3.52c0.06,-0.28 0.2,-1.23 0.3,-1.41l0.44 2.71c0.1,0.49 0.17,0.87 0.27,1.36 0.18,0.9 0.07,2.01 1.69,1.77 0.66,-0.1 0.82,-0.51 0.95,-1.06l1.42 -5.48c0.12,-0.46 0.33,-0.92 -0.08,-1.36 -0.38,-0.4 -1.05,-0.41 -1.39,0.1 -0.18,0.26 -0.67,2.81 -0.76,3.42l-0.38 2.11z"
        />
    </svg>
);

export default Newest;
