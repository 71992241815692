import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosClient from '../../config/axios-interceptors';
import Loader from '../../components/Loader';
import { ImageModal } from '../../components/ImageModal';
import ImageGallery from "./components/ImageGallery";
import Product from "../Products/components/Product";
import useAddToBasket from '../../hooks/useAddToBasket';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';


const ProductInfo = () => {
    const { id } = useParams();
    const [product, setProduct] = React.useState({});
    const [availableGoldTypes, setAvailableGoldTypes] = React.useState([]);
    const [items, setItems] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const navigate = useNavigate();
    const { selectedSize, setSelectedSize, handleBasketClick } = useAddToBasket();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1100);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1100);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        setLoading(true);
        axiosClient.get(`/products/${id}`).then((response) => {
            setProduct(response.data.product);
            setAvailableGoldTypes(response.data.available_gold_types);
            setLoading(false);
        });
    }, [id]);

    useEffect(() => {
        axiosClient.get('/products/random').then((response) => {
            setItems(response.data);
        });
        window.scrollTo(0, 0);
    }, []);

    const handleClick = async (product_id) => {
        try {
            toast.success('Добавлено в избранное');
            const response = await axiosClient.post('/favourites', {
                product_id: product_id,
            });
            console.log(response.data);
        } catch (error) {
            console.error('Error:', error);
            toast.error('Ошибка, слишком много запросов!');
        }
    };

    const parseSizes = (sizeRange) => {
        if (!sizeRange) return [];
        const [min, max] = sizeRange.split(' - ').map(parseFloat);
        const sizes = [];
        for (let size = min; size <= max; size += 0.5) {
            sizes.push(size.toFixed(1));
        }
        return sizes;
    };

    const images = ['/catalogImages/111001157.3.png', '/catalogImages/111001157.1.png', '/catalogImages/111001157.4.png'];

    if (product && product.name) {
        product.name = product.name.replace(/\(Au 585\)/, '').trim();
    }

    return (
        <div className={'w-full h-full flex flex-col items-center justify-center'}>
            <div className={'w-full xl:w-[90%] h-full mt-0 lg:mt-10'}>
                <ImageModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} imageSrc={selectedImage} />
                <div className={'w-full h-[80%] flex flex-col lg:flex-row items-center justify-around'}>
                    <div
                        className={
                            'w-full lg:w-[50%] xl:w-[50%] py-20 px-10 lg:py-36 lg:px-20 h-full bg-appColor rounded-3xl relative'
                        }
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.7499mm"
                            height="6.7499mm"
                            viewBox="0 0 25.4 25.4"
                            className="fill-customGray size-12 sm:size-16 absolute top-6 left-6 sm:top-8 sm:left-8 cursor-pointer"
                            onClick={() => setIsModalOpen(true)}
                        >
                            <g>
                                <g>
                                    <path d="M4.17 18.55c-0.14,-0.22 -0.48,-0.5 -0.68,-0.71l-1.44 -1.44c-0.37,-0.37 -0.48,-0.53 -1.05,-0.53 -0.51,0 -0.99,0.48 -0.99,0.99v7.54c0,0.51 0.48,0.99 0.99,0.99h7.54c0.56,0 0.99,-0.46 0.99,-1.14 0,-0.53 -0.78,-1.15 -1.25,-1.62l-1.43 -1.45 1.43 -1.4c0.49,-0.49 0.95,-0.95 1.44,-1.44l2.16 -2.16c1.03,-1.03 0.87,-0.84 -1.09,-2.8 -0.87,-0.87 -0.87,-0.54 -2.28,0.87 -0.49,0.49 -0.95,0.95 -1.44,1.44 -0.25,0.25 -2.68,2.72 -2.89,2.86z" />
                                    <path d="M15.88 0.99c0,0.55 0.14,0.67 0.51,1.03l2.17 2.15 -1.43 1.45c-0.49,0.49 -0.95,0.95 -1.44,1.44l-2.16 2.16c-1.03,1.03 -0.87,0.84 1.09,2.8 0.77,0.77 0.85,0.56 1.56,-0.15 0.54,-0.54 4.82,-4.87 5.05,-5.02l2.12 2.15c0.37,0.37 0.48,0.53 1.05,0.53 0.51,0 0.99,-0.48 0.99,-0.99v-7.54c0,-0.51 -0.48,-0.99 -0.99,-0.99h-7.54c-0.51,0 -0.99,0.48 -0.99,0.99z" />
                                </g>
                            </g>
                        </svg>
                        <img src={selectedImage} alt="" />
                    </div>

                    <div className={'w-full lg:mt-0 mt-10 lg:w-[45%] xl:w-[40%] h-full'}>
                        <h1 className={'text-4xl font-bold text-customGray flex items-start font-sans'}>{product.name}</h1>
                        <span className={'font-bold text-customGray text-3xl flex items-start font-sans '}>
                            {product.sample} проба
                        </span>

                        <div className={'mt-5'}>
                            <h1 className={'text-2xl font-bold text-customGray flex items-start'}>Артикул</h1>
                            <div className={'w-full bg-grayLight text-3xl text-customGray flex items-start py-1 px-2 mt-1'}>
                                {product.vendor}
                            </div>

                            <h1 className={'text-2xl font-bold text-customGray flex items-start mt-5'}>Вес</h1>
                            <div className={'w-full bg-grayLight text-3xl text-customGray flex items-start py-1 px-2 mt-1'}>
                                {product.average_weight} гр
                            </div>

                            <h1 className={'text-2xl font-bold text-customGray flex items-start mt-5'}>Цена за грамм</h1>
                            <div className={'w-full bg-grayLight text-3xl text-customGray flex items-start py-1 px-2 mt-1'}>
                                {product.price} ₸
                            </div>
                        </div>

                        <div className={'w-full flex items-center  my-6'}>
                            <div className={`w-1/2 flex items-center justify-center flex-col hover:cursor-pointer ${availableGoldTypes.includes('yellow') ? 'opacity-100' : 'opacity-50'}`}>
                                <img src="/catalogImages/желтое%20золото_icon.png" alt="" className={'w-16 h-11'} />
                                <span className={'text-2xl text-customGray'}>Желтое золото</span>
                            </div>

                            <div className={`w-1/2 flex items-center justify-center flex-col hover:cursor-pointer ${availableGoldTypes.includes('red') ? 'opacity-100' : 'opacity-50'}`}>
                                <img src="/catalogImages/красное%20золото_icon.png" alt="" className={'w-16 h-11'} />
                                <span className={'text-2xl text-customGray'}>Красное золото</span>
                            </div>
                        </div>

                        <div className={'flex items-center mt-5'}>
                            <h1 className={'text-2xl font-bold text-customGray flex items-start'}>Вставки</h1>
                            <span className={'ml-7 text-gray-500 text-2xl'}>
                                {product.insert ? `( ${product.insert} )` : 'Не указано'}
                            </span>
                        </div>

                        {/*size*/}
                        <div className={`${product.size ? 'flex flex-col' : ' flex flex-row'}  mt-2`}>
                            <h1 className={'text-2xl font-bold text-customGray flex items-start mb-4 mr-6'}>Размер</h1>
                            <div className={' flex flex-wrap'}>
                                {product.size ? (
                                    parseSizes(product.size).map((size) => (
                                        <span
                                            key={size}
                                            className={`
                                            mr-1 mb-2 px-4 text-gray-600 py-2 border-[1.5px] border-gray-700 rounded-xl hover:cursor-pointer
                                            ${selectedSize === size ? 'bg-gray-300' : 'bg-white'}
                                        `}
                                            onClick={() => setSelectedSize(size)}
                                        >
                                            {size}
                                        </span>
                                    ))
                                ) : (
                                    <span className={'text-customGray text-2xl'}>Размер не указан</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'w-full max-h-[20%] mt-10 flex flex-col xl:flex-row items-center justify-between xl:px-16'}>
                    <div className={'w-full xl:w-[50%] h-full'}>
                        <ImageGallery images={images} onSelectImage={setSelectedImage} />
                    </div>

                    <div className={'w-full mt-5 xl:ml-12 2xl:ml-32 xl:mt-0 xl:w-[50%] flex items-center'}>
                        <button
                            onClick={() => handleBasketClick(product.id, product.size)}
                            className={
                                'hover:cursor-pointer bg-customGray text-amber-50 font-bold text-2xl py-4 px-4 md:py-8 md:px-8 2xl:py-8 2xl:px-20 rounded-xl hover:text-gray-400 hover:transition  '
                            }
                        >
                            <span className={'font-sans'}>Добавить в корзину</span>
                        </button>
                        <div
                            onClick={() => handleClick(product.id)}
                            className={'bg-customGray ml-4 py-6 px-6 rounded-xl  hover:cursor-pointer'}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32.6266mm"
                                height="26.2717mm"
                                viewBox="0 0 150.93 121.54"
                                className="fill-amber-50 w-12 h-12  transition-transform duration-300 transform hover:scale-125 "
                            >
                                <path d="M75.47 121.54c-3.94,0 -7.89,-1.52 -10.9,-4.52l-53.71 -53.71c-14.49,-14.48 -14.49,-37.96 0,-52.44 7.22,-7.27 16.75,-10.88 26.23,-10.88 9.48,0 19.01,3.6 26.23,10.88l12.14 12.11 11.48 -11.48c7.44,-7.45 17.37,-11.26 27.21,-11.26 9.35,0 18.65,3.43 25.79,10.45 7.36,7.27 10.99,16.81 10.99,26.41 0,9.46 -3.59,18.96 -10.86,26.2l-53.71 53.71c-3.01,3 -6.96,4.52 -10.9,4.52z" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'w-full mt-20 flex items-center justify-center'}>
                <div className={'w-[84%]'}>
                    <div className={'w-full flex items-center justify-center'}>
                        <span className={'font-sans text-5xl text-customGray font-bold'}>Может понравиться</span>
                    </div>
                    <div className={'w-full mt-10'}>
                        <Swiper
                            modules={[Autoplay]}
                            spaceBetween={20}
                            slidesPerView={isMobile ? 2 : 5}
                            autoplay={{ delay: 2000, disableOnInteraction: false }}
                            loop={true}
                        >
                            {items.map((item) => (
                                <SwiperSlide key={item.id}>
                                    <div className={'w-full'}>
                                        <div className="relative w-full h-full flex items-center justify-center bg-appColor py-10 px-4 rounded-2xl">
                                            <img
                                                className="max-w-full max-h-full object-contain"
                                                src="/catalogImages/111001157.3.png"
                                                alt={item.product_name}
                                            />
                                        </div>

                                        <div className="pt-5">
                                            <div className="flex items-center justify-between px-3">
                                                <span className="font-bold text-2xl">
                                                    {item.average_weight == 0 ? item.price : item.price * item.average_weight} ₸
                                                </span>
                                                <div className="hidden md:flex">
                                                    <svg
                                                        onClick={() => handleClick(item.id)}
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="32.6266mm"
                                                        height="26.2717mm"
                                                        viewBox="0 0 150.93 121.54"
                                                        className="fill-customGray w-6 h-6 hover:cursor-pointer transition-transform duration-300 transform hover:scale-125"
                                                    >
                                                        <path d="M75.47 121.54c-3.94,0 -7.89,-1.52 -10.9,-4.52l-53.71 -53.71c-14.49,-14.48 -14.49,-37.96 0,-52.44 7.22,-7.27 16.75,-10.88 26.23,-10.88 9.48,0 19.01,3.6 26.23,10.88l12.14 12.11 11.48 -11.48c7.44,-7.45 17.37,-11.26 27.21,-11.26 9.35,0 18.65,3.43 25.79,10.45 7.36,7.27 10.99,16.81 10.99,26.41 0,9.46 -3.59,18.96 -10.86,26.2l-53.71 53.71c-3.01,3 -6.96,4.52 -10.9,4.52z" />
                                                    </svg>
                                                    <svg
                                                        onClick={() => handleBasketClick(item.id, item.size)}
                                                        className="w-6 h-6 text-gray-600 ml-1 hover:cursor-pointer transition-transform duration-300 transform hover:scale-125"
                                                        viewBox="0 0 77.18 72.55"
                                                    >
                                                        <g>
                                                            <path
                                                                className="fill-customGray"
                                                                d="M0 2.58c0 4.84 5.11 2.68 8.03 3.37 0.83 0.2 1.1 0.47 1.58 0.99 0.48 0.52 0.61 1.1 0.85 1.88 2.86 9.45 5.45 19.66 8.26 28.95 0.85 2.8 1.51 5.49 2.37 8.34 0.73 2.42 1.52 6.09 2.69 7.84 1.21 1.82 3.98 3.82 7.02 3.82h33.21c1.68 0 3.3-0.82 4.38-1.51 3.15-2.04 3.52-4.69 4.17-7.85l3.77-20.14c0.64-3.3 1.82-6.26-0.63-7.66-1.19-0.68-9.04-0.35-10.77-0.35-14.9 0-29.81 0-44.71 0-1.04-2.1-3.57-13.87-5.46-16.52-0.83-1.16-1.94-2.14-3.25-2.79-2.6-1.29-5.58-0.9-8.91-0.9-1.16 0-2.6 1.4-2.6 2.53zm50.46 63.82c0 6.29 6.84 7.7 10.16 4.47 3.32-3.23 1.87-9.88-4.6-9.88-2.86 0-5.57 2.63-5.57 5.41zm-17.81 0c0 6.29 6.84 7.7 10.16 4.47 3.32-3.23 1.87-9.88-4.6-9.88-2.86 0-5.57 2.63-5.57 5.41z"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="w-full">
                                                <h1 className="ml-3 text-xl flex items-start">
                                                    {item.product_name.replace(/\(Au 585\)/, '').trim()}
                                                </h1>
                                                <h1 className="ml-3 text-xl flex items-start">
                                                    {item.sample ? item.sample.match(/\d+/)[0] + ' пробы' : 'Не указан'}
                                                </h1>
                                                <span className="flex items-start ml-3 text-sm text-gray-400 mb-8">Арт. {item.vendor}</span>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductInfo;