import React from 'react';
import { motion } from 'framer-motion';

const SingleProductOrderModal = ({ item, onClose, onOrder }) => {
    return (
        <motion.div
            initial={{ opacity: 0, y: '100%' }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: '100%' }}
            transition={{ type: 'spring', damping: 25, stiffness: 500 }}
            className="fixed inset-0 bg-white z-50 overflow-y-auto"
        >
            <div className="container mx-auto p-4">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 }}
                    className="w-full max-w-sm border border-gray-300 rounded-lg p-4 my-4 mx-auto"
                >
                    <div className="flex justify-between items-center mb-4">
                        <span className="text-lg font-semibold">Оформить заказ</span>
                    </div>
                    <div className="flex justify-between items-center mb-2">
                        <span className="text-sm text-gray-600">{item.product_name}</span>
                    </div>
                    <div className="flex justify-between items-center mb-4">
                        <span className="text-sm text-gray-600">Цена: {item.price * item.average_weight * item.quantity} ₸</span>
                    </div>
                    <motion.button
                        whileHover={{ backgroundColor: '#333' }}
                        whileTap={{ scale: 0.95 }}
                        className="w-full bg-black text-white py-3 rounded-lg text-md font-bold"
                        onClick={onOrder}
                    >
                        Оформить заказ
                    </motion.button>
                    <motion.button
                        whileHover={{ backgroundColor: '#f0f0f0' }}
                        whileTap={{ scale: 0.95 }}
                        className="w-full bg-white text-black py-3 rounded-lg text-md font-bold mt-2 border border-black"
                        onClick={onClose}
                    >
                        Вернуться в корзину
                    </motion.button>
                </motion.div>
            </div>
        </motion.div>
    );
};

export default SingleProductOrderModal;