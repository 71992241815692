import React, {useState} from 'react';
import Modal from '../../../components/SidebarModal';
import ProductSidebarMobileContent from './ProductSidebarMobileContent';

const ProductsSidebar = ({
                             setMaxPrice,
                             setMinPrice,
                             minPrice,
                             maxPrice,
                             selecteds,
                             setSelecteds,
                             onPriceChange,
                             products,
                             onFilterProducts,
                             setLoading,
                             goldType,
                             setGoldType,
                             totalProducts,
                             catalogId
                         }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
      <>
        <div className={'p-0 mt-10 lg:mt-[70px] sm:mx-0 sm:p-4 min-w-[20%]'}>
          <div className={'w-full flex items-center justify-between mb-6'}>
            <span className={'text-3xl sm:text-5xl ml-2'}>Фильтры</span>
            <div className={"flex bg-appColor p-0.5 mr-3 rounded-2xl items-center justify-center"}>
              <button className="size-8 flex items-center justify-center" onClick={() => setIsModalOpen(true)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <ProductSidebarMobileContent
            setMaxPrice={setMaxPrice}
            setMinPrice={setMinPrice}
            minPrice={minPrice}
            maxPrice={maxPrice}
            selecteds={selecteds}
            setSelecteds={setSelecteds}
            onPriceChange={onPriceChange}
            products={products}
            onFilterProducts={onFilterProducts}
            setLoading={setLoading}
            goldType={goldType}
            setGoldType={setGoldType}
            totalProducts={totalProducts}
            catalogId={catalogId}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />
        </Modal>
      </>
    );
};

export default ProductsSidebar;
