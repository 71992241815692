const Gold = () => (
    <svg
        className="size-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 43.55 40.27"
        fill="none"
    >
        <g>
            <path
                className="fill-current"
                d="M11.62 23.14c0 1 0.66 1.65 1.36 1.65l17.52 0c0.7 0 1.36-0.64 1.36-1.65 0-0.49-0.7-2.34-0.88-2.89-0.31-0.93-2.16-6.76-2.42-7.16-0.25-0.39-0.63-0.69-1.25-0.69l-11.13 0c-1.25 0-1.47 1.16-1.75 2.02-0.34 1.02-2.8 8.31-2.8 8.73z"
            />
            <path
                className="fill-current"
                d="M0 38.72c0 0.7 0.65 1.55 1.36 1.55l17.52 0c0.7 0 1.36-0.64 1.36-1.65 0-0.55-0.69-2.26-0.87-2.9l-2.32-7.07c-0.17-0.31-0.68-0.78-1.16-0.78l-11.42 0c-1.13 0-1.41 1.48-1.63 2.14-0.33 0.98-0.65 1.95-0.97 2.9l-1.85 5.8z"
            />
            <path
                className="fill-current"
                d="M23.33 38.72c0 0.76 0.64 1.55 1.36 1.55l17.43 0c0.31 0 0.54-0.14 0.76-0.31l0.53-0.63c0.33-0.76 0.04-1.11-0.26-2.03l-2.87-8.65c-0.17-0.31-0.68-0.78-1.16-0.78l-11.33 0c-1.11 0-1.44 1.47-1.66 2.12-0.32 0.97-2.8 8.36-2.8 8.72z"
            />
            <path
                className="fill-current"
                d="M20.23 1.36l0 5.03c0 0.7 0.67 1.36 1.74 1.36 0.6 0 1.36-0.75 1.36-1.36l0-5.03c0-0.7-0.67-1.36-1.74-1.36-0.6 0-1.36 0.75-1.36 1.36z"
            />
            <path
                className="fill-current"
                d="M3.19 7.65c0 1.15 0.86 1.68 1.96 2.78 1.1 1.1 1.62 1.96 2.78 1.96 0.88 0 1.45-0.67 1.45-1.65 0-0.91-1.14-1.77-1.96-2.59-0.39-0.39-0.72-0.72-1.11-1.11-0.65-0.66-0.87-0.85-1.67-0.85-0.79 0-1.45 0.66-1.45 1.45z"
            />
            <path
                className="fill-current"
                d="M34.17 10.75c0 1.16 0.67 1.65 1.74 1.65 0.77 0 2-1.47 2.49-1.96 0.91-0.91 1.96-1.61 1.96-2.78 0-0.99-1.36-2.39-3.07-0.65l-2.27 2.28c-0.39 0.39-0.85 0.74-0.85 1.48z"
            />
        </g>
    </svg>
);

export default Gold;
