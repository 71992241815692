import React, { useContext, useState } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import { AuthUserContext } from "../../contexts/AuthUserContext";
import { CatalogContext } from "../../contexts/CatalogContext";
import { AnimatePresence, motion } from 'framer-motion';
import {ModalContext} from "../../contexts/ModalContext";
import {CityContext} from "../../contexts/CityContext";

const MobileHeader = () => {
    const navigate = useNavigate();
    const { authUser } = useContext(AuthUserContext);
    const { isOpenCatalog, setIsOpenCatalog } = useContext(CatalogContext);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const  location = useLocation();
    const {isOpen , setIsOpen} = useContext(ModalContext);
    const {city, setCity} = useContext(CityContext);


    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
    const handleCatalog = () => {
        setIsOpenCatalog(!isOpenCatalog);
        setIsMenuOpen(false);
    };

    const handleCity = () => {
        setIsOpen(!isOpen);
    }

    const menuItems = [
        { text: "Главная", path: "/" },
        { text: "Каталог", path: null, action: handleCatalog },
        { text: "Корзина", path: "/basket" },
        { text: "Избранное", path: "/favourites" },
        { text: `${authUser ? "Личный кабинет" : "Войти"}`, path: authUser ? "/me" : "/login" },
    ];

    return (
      <>
        <header className={`w-full h-[50px] flex items-center justify-between px-2 py-8 bg-white z-20 ${location.pathname === '/basket' ? 'hidden' : ''}`}>
          <div
            className="min-w-[40px] min-h-[40px] text-black font-bold text-2xl flex items-center justify-center bg-grayLight rounded-xl hover:cursor-pointer"
            onClick={() => navigate('/')}
          >
            A
          </div>
          <div className="w-full flex items-center ml-4">
            <div className="relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 absolute top-1/2 transform -translate-y-1/2 left-3"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
            </div>
            <input
              type="text"
              placeholder="Поиск по ассортименту"
              className="w-full bg-grayLight rounded-xl text-sm pl-10 pr-4 py-2.5"
            />
          </div>
          <div className="bg-grayLight p-2 rounded-full ml-3 hover:cursor-pointer" onClick={toggleMenu}>
            {isMenuOpen ? (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                <path
                  fill-rule="evenodd"
                  d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
                  clip-rule="evenodd"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="3"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
            )}
          </div>
        </header>

        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className="absolute top-[70px] left-0 w-full bg-white shadow-lg z-10"
            >
              <nav className="flex flex-col items-center py-4">
                {menuItems.map((item, index) => (
                  <div
                    key={index}
                    className="w-full flex items-center justify-between px-6 py-3 border-b border-gray-200 hover:bg-gray-100"
                    onClick={() => {
                      if (item.path) {
                        navigate(item.path);
                      } else if (item.action) {
                        item.action();
                      }
                      setIsMenuOpen(false);
                    }}
                  >
                    <span className="font-medium">{item.text}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                      <path
                        fillRule="evenodd"
                        d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                ))}
              </nav>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );
};

export default MobileHeader;