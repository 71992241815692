import React, { useContext, useEffect, useRef, useState } from 'react';
import { FaLocationDot } from 'react-icons/fa6';
import { AnimatePresence, motion } from 'framer-motion';
import axiosClient from '../../config/axios-interceptors';
import { SearchContext } from '../../contexts/SearchContext';
import { CatalogContext } from '../../contexts/CatalogContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthUserContext } from '../../contexts/AuthUserContext';
import { CityContext } from '../../contexts/CityContext';
import { ModalContext } from '../../contexts/ModalContext';
import { BasketContext } from '../../contexts/BasketContext';
import { FavouriteContext } from '../../contexts/FavouriteContext';

const DesktopHeader = () => {
  const { searchValue, setSearchValue } = useContext(SearchContext);
  const { setCatalogId } = useContext(CatalogContext);
  const navigate = useNavigate();
  const { authUser } = useContext(AuthUserContext);
  const { isOpenCatalog, setIsOpenCatalog } = useContext(CatalogContext);
  const { city } = useContext(CityContext);
  const { isOpen, setIsOpen } = useContext(ModalContext);
  const { baskets, setBaskets } = useContext(BasketContext);
  const { favourites, setFavourites } = useContext(FavouriteContext);
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    axiosClient.get('/fetchAllProducts').then((response) => {
      setProducts(response.data.data || []);
    });
  }, []);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location.pathname]);

  const handleCatalog = () => {
    setIsOpenCatalog(!isOpenCatalog);
    setIsMenuOpen(false);
  };

  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    if (value.trim() === '') {
      setFilteredSuggestions([]);
    } else {
      setFilteredSuggestions(
        (products || []).filter((product) => product.name.toLowerCase().includes(value.toLowerCase()))
      );
    }
  };
  const handleNavigate = (id) => {
    navigate(`/products/${id}`);
    setSearchValue('');
    setFilteredSuggestions('');
  };

  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <>
      {isMenuOpen && (
        <div className="fixed overflow-auto inset-0 bg-white z-50 flex flex-col">
          <div className="flex justify-end">
            <button onClick={toggleMenu} className="text-2xl">
              &times;
            </button>
          </div>
          <nav className="flex flex-col items-center mt-10">
            <div
              className={
                'w-full h-[10%] flex items-center justify-between p-10 border-2 border-gray-400 rounded-2xl mx-4'
              }
              onClick={() => navigate('/')}
            >
              <span className={'font-bold'}>Главная</span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                <path
                  fill-rule="evenodd"
                  d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>

            <div
              onClick={() => handleCatalog()}
              className={
                'w-full h-[10%] flex items-center justify-between p-10 border-2 border-gray-400 rounded-2xl mx-4 mt-5'
              }
            >
              <span className={'font-bold '}>Каталог</span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                <path
                  fill-rule="evenodd"
                  d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>

            <div
              onClick={() => navigate('/basket')}
              className={
                'w-full h-[10%] flex items-center justify-between p-10 border-2 border-gray-400 rounded-2xl mx-4 mt-5'
              }
            >
              <span className={'font-bold'}>Корзина</span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                <path
                  fill-rule="evenodd"
                  d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>

            <div
              onClick={() => navigate('/favourites')}
              className={
                'w-full h-[10%] flex items-center justify-between p-10 border-2 border-gray-400 rounded-2xl mx-4 mt-5'
              }
            >
              <span className={'font-bold'}>Избранное</span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="" className="size-6">
                <path
                  fill-rule="evenodd"
                  d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>

            <div
              onClick={() => navigate(authUser ? '/me' : '/login')}
              className={
                'w-full h-[10%] flex items-center justify-between p-10 border-2 border-gray-400 rounded-2xl mx-4 mt-5'
              }
            >
              <span className={'font-bold'}>Войти</span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                <path
                  fill-rule="evenodd"
                  d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </nav>
        </div>
      )}

      <header
        className={`w-full py-10 px-10 flex items-center justify-center flex-col transition-all ${location.pathname === '/products' ? 'shadow-lg' : ''} duration-300 ${
          isScrolled ? 'fixed top-0 z-50 bg-white shadow-lg' : 'relative'
        } ${isMenuOpen ? 'hidden' : ''}`}
      >
        {!isScrolled && (
          <div
            className={`w-full items-center flex ${location.pathname === '/products' ? 'hidden' : ''} py-4 px-4 sm:px-8 bg-white font-[sans-serif] min-h-[50px] tracking-wide ${location.pathname === '/products' ? '2xl:w-[90%]' : '2xl:w-[83.5%]'}`}
          >
            <div className={'min-w-1/6 text-gray-700 flex items-center'}>
              <FaLocationDot className={'-mt-1 text-customGray'} />
              {city.length ? (
                <span className={'text-md text-black ml-1 hover:cursor-pointer'} onClick={() => setIsOpen(!isOpen)}>
                  {city}
                </span>
              ) : (
                <span className={'text-sm ml-1 hover:cursor-pointer'} onClick={() => setIsOpen(!isOpen)}>
                  Выберите
                </span>
              )}
              <div className={'flex justify-center'}>
                <span className={'ml-4 text-md text-black'}>{user ? user.phone : ''}</span>
              </div>
            </div>
            <div className={'w-full flex items-center justify-around ml-4'}>
              <a href="#" className="text-customGray text-lg font-bold font-sans">
                Обмен и возврат
              </a>
              <a href="#" className="text-customGray text-lg font-bold font-sans">
                Статус заказа
              </a>
              <a href="#" className="text-customGray text-lg font-bold font-sans">
                Магазины
              </a>
              <a href="#" className="text-customGray text-lg font-bold font-sans">
                Доставка и оплата
              </a>
            </div>
            <div className="flex space-x-1 bg-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 66.63 66.63"
                className="fill-gray-600 size-7 hover:cursor-pointer"
              >
                <g id="Слой_x0020_1">
                  <path
                    d="M57.69 66.63H8.95c-2.46 0-4.7-1.01-6.32-2.63C0.98 62.38 0 60.14 0 57.68V8.95C0 6.49 0.98 4.25 2.63 2.63 4.25 1.01 6.49 0 8.95 0h48.74c2.46 0 4.7 1.01 6.32 2.63 1.62 1.62 2.63 3.86 2.63 6.32v48.74c0 2.46-1.01 4.7-2.63 6.32-1.62 1.62-3.86 2.63-6.32 2.63zM8.95 63H57.69c1.46 0 2.79-0.6 3.75-1.56 0.96-0.96 1.56-2.29 1.56-3.75V8.95c0-1.46-0.6-2.79-1.56-3.75-0.96-0.96-2.29-1.56-3.75-1.56H8.95C7.49 3.64 6.16 4.24 5.2 5.2c-0.96 0.96-1.56 2.29-1.56 3.75v48.74c0 1.46 0.6 2.79 1.56 3.75C6.16 62.4 7.49 63 8.95 63z"
                    className="fill-gray-600"
                  />
                  <path
                    d="M20.62 40.4H9.07V20.35h11.55v3.48h-7.3v4.41h6.79v3.48h-6.79v5.17h7.3v3.51zm12.56-15.45c1.64 0 2.95 0.44 3.95 1.32 1 0.88 1.49 2.3 1.49 4.24v9.89h-4.18v-8.83c0-1.08-0.2-1.9-0.6-2.45-0.4-0.55-1.02-0.82-1.87-0.82-1.28 0-2.15 0.43-2.62 1.28-0.47 0.86-0.7 2.09-0.7 3.71v7.11h-4.18V24.95h3.19l0.57 1.97h0.23c0.33-0.53 0.74-0.96 1.23-1.29 0.49-0.33 1.03-0.57 1.63-0.73 0.6-0.15 1.22-0.23 1.86-0.23zm14.23 22.19c-2.14 0-3.77-0.37-4.91-1.1-1.13-0.74-1.69-1.77-1.69-3.1 0-0.92 0.29-1.68 0.86-2.29 0.58-0.62 1.42-1.05 2.54-1.32-0.43-0.18-0.81-0.48-1.12-0.9-0.32-0.41-0.48-0.85-0.48-1.32 0-0.59 0.17-1.07 0.51-1.46 0.34-0.39 0.83-0.77 1.47-1.14-0.8-0.35-1.44-0.9-1.91-1.66-0.47-0.76-0.71-1.66-0.71-2.69 0-1.1 0.24-2.05 0.73-2.82 0.49-0.78 1.2-1.37 2.14-1.77 0.94-0.41 2.08-0.61 3.42-0.61 0.29 0 0.61 0.02 0.99 0.06 0.37 0.04 0.72 0.08 1.04 0.12 0.31 0.05 0.52 0.08 0.64 0.11l5.35 0v2.13l-2.4 0.61c0.22 0.34 0.38 0.69 0.49 1.07 0.11 0.38 0.16 0.78 0.16 1.21 0 1.63-0.57 2.9-1.72 3.81-1.15 0.91-2.74 1.36-4.78 1.36-0.49-0.03-0.95-0.07-1.37-0.11-0.21 0.16-0.37 0.33-0.48 0.52-0.11 0.18-0.16 0.37-0.16 0.58 0 0.2 0.08 0.37 0.25 0.5 0.17 0.13 0.43 0.23 0.77 0.3 0.34 0.07 0.77 0.1 1.28 0.1l2.61 0c1.68 0 2.96 0.36 3.85 1.07 0.88 0.72 1.32 1.77 1.32 3.16 0 1.78-0.74 3.16-2.24 4.13-1.49 0.98-3.64 1.47-6.44 1.47zm0.18-2.73c1 0 1.85-0.09 2.57-0.28 0.72-0.18 1.27-0.44 1.65-0.78 0.38-0.33 0.58-0.74 0.58-1.2 0-0.38-0.11-0.67-0.34-0.89-0.22-0.22-0.56-0.37-1.01-0.46-0.45-0.09-1.03-0.13-1.74-0.13h-2.17c-0.51 0-0.97 0.08-1.38 0.24-0.41 0.16-0.73 0.39-0.97 0.68-0.24 0.29-0.36 0.63-0.36 1.01 0 0.57 0.28 1.01 0.83 1.33 0.55 0.32 1.33 0.48 2.33 0.48zm0.67-11.53c0.79 0 1.36-0.24 1.73-0.72 0.37-0.48 0.55-1.12 0.55-1.94 0-0.91-0.19-1.59-0.57-2.05-0.38-0.45-0.95-0.68-1.71-0.68-0.77 0-1.34 0.23-1.73 0.68-0.38 0.45-0.58 1.13-0.58 2.05 0 0.82 0.19 1.47 0.57 1.94 0.38 0.48 0.96 0.72 1.73 0.72z"
                    className="fill-gray-600"
                  />
                </g>
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 78.91 78.91"
                className="fill-customGray size-7 hover:cursor-pointer"
              >
                <g>
                  <path
                    d="M69.98 78.91H8.93C4.02 78.91 0 74.89 0 69.98V8.93C0 4.02 4.02 0 8.93 0h61.05C74.89 0 78.91 4.02 78.91 8.93v61.05c0 4.91-4.02 8.93-8.93 8.93z"
                    className="fill-customGray"
                  />
                  <path
                    d="M30.54 51.09h-5.68L16.3 39.13v11.96h-5V27.49h5v11.43l8.46-11.43h5.33l-8.6 11.33 9.05 12.27zm9.98-18.18c2.42 0 4.27 0.52 5.56 1.56 1.29 1.04 1.94 2.63 1.94 4.75v11.87h-3.44l-0.95-2.45h-0.13c-0.52 0.64-1.05 1.17-1.59 1.58-0.53 0.41-1.16 0.71-1.85 0.9-0.7 0.19-1.55 0.29-2.55 0.29-1.07 0-2.02-0.2-2.87-0.61-0.85-0.4-1.51-1.02-1.99-1.86-0.48-0.83-0.73-1.89-0.73-3.17 0-1.9 0.67-3.28 2.02-4.18 1.34-0.9 3.37-1.39 6.06-1.49l3.13-0.09v-0.78c0-0.93-0.25-1.6-0.74-2.03-0.49-0.44-1.18-0.65-2.07-0.65-0.87 0-1.72 0.13-2.56 0.37-0.84 0.25-1.68 0.56-2.52 0.94l-1.63-3.32c0.96-0.51 2.03-0.9 3.22-1.2 1.19-0.29 2.42-0.43 3.69-0.43zm2.61 9.98l-1.9 0.07c-1.6 0.04-2.7 0.32-3.32 0.83-0.62 0.51-0.93 1.18-0.93 2.02 0 0.73 0.22 1.25 0.66 1.56 0.44 0.31 1.02 0.46 1.73 0.46 1.05 0 1.94-0.3 2.67-0.91 0.73-0.61 1.09-1.47 1.09-2.58v-1.44zm16.53-9.98c1.3 0 2.52 0.17 3.65 0.5 1.13 0.34 2.04 0.85 2.74 1.55 0.7 0.69 1.05 1.59 1.05 2.68 0 1.07-0.33 1.93-0.99 2.56-0.66 0.63-1.53 1.09-2.62 1.36v0.17c0.77 0.18 1.46 0.42 2.08 0.73 0.62 0.31 1.11 0.74 1.48 1.28 0.36 0.55 0.55 1.26 0.55 2.14 0 0.99-0.32 1.91-0.95 2.74-0.64 0.84-1.61 1.51-2.91 2.03-1.31 0.51-2.99 0.77-5.03 0.77-0.96 0-1.88-0.04-2.73-0.13-0.86-0.09-1.64-0.21-2.35-0.38-0.71-0.17-1.33-0.37-1.85-0.61v-4.07c0.74 0.34 1.66 0.66 2.75 0.95 1.09 0.29 2.26 0.44 3.49 0.44 0.89 0 1.7-0.06 2.43-0.19 0.73-0.13 1.31-0.34 1.73-0.64 0.42-0.3 0.63-0.71 0.63-1.21 0-0.47-0.18-0.86-0.56-1.17-0.37-0.31-0.94-0.55-1.69-0.69-0.76-0.15-1.72-0.23-2.88-0.23h-1.91v-3.41h1.81c1.64 0 2.9-0.13 3.8-0.39 0.89-0.26 1.34-0.74 1.34-1.44 0-0.56-0.29-0.99-0.85-1.27-0.57-0.28-1.51-0.42-2.83-0.42-0.85 0-1.79 0.1-2.81 0.3-1.02 0.2-1.98 0.48-2.87 0.85l-1.45-3.44c1.04-0.4 2.18-0.72 3.4-0.98 1.22-0.25 2.68-0.38 4.36-0.38z"
                    className="fill-white"
                  />
                </g>
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 71.88 71.88"
                className="fill-gray-600 size-7 hover:cursor-pointer"
              >
                <path d="M62.23 71.88H9.65c-2.66 0-5.07-1.09-6.82-2.83S0 64.66 0 62V9.42C0 6.76 1.09 4.35 2.83 2.6S7 0 9.65 0h52.58c2.66 0 5.07 1.09 6.82 2.83S71.88 7 71.88 9.65v52.58c0 2.66-1.09 5.07-2.83 6.82s-4.16 2.83-6.82 2.83zM9.65 67.96h52.58c1.57 0 3.01-.65 4.05-1.69 1.04-1.04 1.69-2.47 1.69-4.05V9.65c0-1.57-.65-3.01-1.69-4.05-1.04-1.04-2.47-1.69-4.05-1.69H9.65c-1.57 0-3.01.65-4.05 1.69-1.04 1.04-1.69 2.47-1.69 4.05v52.58c0 1.57.65 3.01 1.69 4.05 1.04 1.04 2.47 1.69 4.05 1.69z" />
                <path d="M19.01 21.95c2.79 0 4.83.6 6.11 1.8 1.28 1.2 1.92 2.85 1.92 4.95 0 .95-.14 1.85-.43 2.72-.29.86-.75 1.63-1.4 2.3-.65.67-1.5 1.2-2.58 1.59-1.08.39-2.4.59-3.98.59h-1.97v7.7h-4.58V21.95h6.91zm-.24 3.76h-2.09v6.42h1.51c.86 0 1.61-.12 2.24-.34.63-.23 1.12-.58 1.46-1.07.34-.48.52-1.1.52-1.86 0-1.07-.3-1.85-.89-2.37-.59-.52-1.51-.78-2.75-.78zm9.63 1.51h4.94l3.12 9.23c.1.3.18.6.25.9.07.3.13.62.18.94.05.32.09.66.12 1.01h.09c.06-.53.14-1.02.24-1.48.11-.46.23-.91.39-1.37l3.06-9.23h4.84l-7 18.51c-.42 1.13-.97 2.08-1.66 2.84-.68.76-1.46 1.33-2.34 1.71-.88.38-1.85.57-2.91.57-.51 0-.95-.03-1.33-.08-.37-.06-.69-.11-.96-.17v-3.58c.21.05.47.1.78.14.32.04.65.06.99.06.63 0 1.17-.13 1.63-.39.45-.26.83-.62 1.14-1.06.3-.44.55-.92.72-1.45l.27-.8-6.57-16.3zm26.32 16.66c-1.64 0-3.03-.29-4.19-.88-1.16-.59-2.03-1.51-2.64-2.75-.6-1.24-.9-2.82-.9-4.76 0-2 .34-3.64 1.03-4.9.69-1.26 1.64-2.19 2.86-2.79 1.22-.6 2.63-.89 4.24-.89 1.14 0 2.13.11 2.96.33.83.22 1.56.48 2.18.79l-1.33 3.49c-.71-.29-1.37-.52-1.98-.7-.61-.18-1.22-.27-1.83-.27-.79 0-1.44.18-1.97.55-.52.36-.91.9-1.17 1.63-.26.73-.38 1.64-.38 2.73 0 1.08.14 1.96.41 2.67.27.71.68 1.23 1.2 1.58.53.34 1.16.52 1.91.52.93 0 1.77-.13 2.5-.37.73-.25 1.44-.61 2.13-1.06v3.86c-.69.43-1.41.75-2.17.94-.76.19-1.71.29-2.86.29z" />
              </svg>
            </div>
          </div>
        )}

        <div
          className={`w-full items-center flex py-4 px-4 sm:px-8 bg-white font-[sans-serif] min-h-[50px] tracking-wide relative z-50 ${
            location.pathname === '/products' ? '2xl:w-[90%]' : '2xl:w-[83.5%]'
          }`}
        >
          <div className={'w-1/6 flex'}>
            <svg
              onClick={() => navigate('/')}
              xmlns="http://www.w3.org/2000/svg"
              width={50}
              viewBox="0 0 390.76 390.76"
              className="fill-customGray text-customGray mr-4 hover:cursor-pointer"
              style={{ minWidth: '50px' }}
            >
              <rect x="0" y="0" width="390.76" height="390.76" rx="44.23" ry="44.23" className="fill-customGray" />
              <path
                d="M143.43 222.08l103.04 0 -51.73 -103.61 -51.3 103.61zm-75.47 90.3l114.69 -234 24.17 0 115.99 234 -31.62 0 -31.76 -62.95 -129.3 0 -30.97 62.95 -31.19 0z"
                className="fill-white"
              />
            </svg>
            <div className="w-full flex items-center justify-center hidden" onClick={toggleMenu}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
            </div>
            <div
              style={{ width: '130px', padding: '8px' }}
              className={`flex px-2.5 border-2 border-customGray rounded-xl font-bold hover:cursor-pointer ${
                isOpenCatalog ? 'bg-white text-black' : 'bg-customGray text-white'
              }`}
              onClick={() => setIsOpenCatalog(!isOpenCatalog)}
            >
              {isOpenCatalog ? (
                <div className="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#000000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                  <span className="ml-2 font-bold text-black text-lg font-sans">Каталог</span>
                </div>
              ) : (
                <div className="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18px"
                    height="20px"
                    viewBox="0 0 27.39 22.16"
                    className="fill-current text-white"
                  >
                    <rect x="0" y="0" width="27.39" height="5.36" className="fill-white" />
                    <rect x="0" y="8.15" width="27.39" height="5.36" className="fill-white" />
                    <rect x="0" y="16.8" width="27.39" height="5.36" className="fill-white" />
                  </svg>
                  <span className="ml-2 font-bold text-white text-lg font-sans">Каталог</span>
                </div>
              )}
            </div>
          </div>
          <div className={'w-full xl:w-[70%] flex relative'}>
            <div className={'relative left-9 top-3'}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
            </div>
            {location.pathname !== '/products' || '/favourites' || '/basket' ? (
              <input
                type="text"
                placeholder={'Поиск по ассортименту'}
                value={searchValue}
                onChange={handleInputChange}
                ref={inputRef}
                className={
                  'w-[85%] placeholder-gray-600 flex border-[2.5px] border-black justify-center bg-white rounded-2xl text-md font-sans px-10 py-2.5 focus:outline-none'
                }
              />
            ) : (
              <input
                type="text"
                placeholder={'Поиск по ассортименту'}
                onChange={(e) => (location.pathname !== '/' ? setSearchValue(e.target.value) : navigate('/products'))}
                className={'w-[80%] border-black border-2 rounded-2xl text-sm px-10 py-2.5'}
              />
            )}
          </div>

          <AnimatePresence>
            {location.pathname !== '/products' &&
              location.pathname !== '/favourites' &&
              location.pathname !== '/basket' &&
              filteredSuggestions.length > 0 && (
                <motion.div
                  className="absolute top-full mt-1 w-full bg-white border border-gray-200 rounded-lg shadow-lg z-40 p-10"
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  <div className="flex flex-col md:flex-row w-full overflow-x-auto overflow-y-auto">
                    {filteredSuggestions.slice(0, 4).map((product, index) => (
                      <div
                        className="w-full md:w-1/3 min-h-[200px] hover:cursor-pointer transition-transform duration-300 transform hover:scale-95"
                        key={index}
                        onClick={() => handleNavigate(product.id)}
                      >
                        <div className="w-full h-[70%]">
                          <img src="/catalogImages/браслеты.png" alt="" className="py-5 px-5" />
                        </div>
                        <div className="w-full h-[30%] flex flex-col mt-10">
                          <span className="font-bold text-sm">{product.name}</span>
                          <span className="font-bold text-sm mt-3">{product.price} ₸</span>
                        </div>
                      </div>
                    ))}

                    <div className={'flex items-center justify-center p-3'}>
                      <span
                        onClick={() => navigate('/products')}
                        className={'font-bold text-gray-400 underline hover:cursor-pointer'}
                      >
                        {' '}
                        Смотреть еще ...
                      </span>
                    </div>
                  </div>
                </motion.div>
              )}
          </AnimatePresence>

          <div className={'w-1/3 ml-4 block'}>
            <ul className={'list-none flex items-center justify-around'}>
              <div
                className={'flex flex-col items-center hover:cursor-pointer'}
                onClick={() => navigate(`${authUser ? '/me' : '/login'}`)}
              >
                <li className={'flex items-center'}>
                  {location.pathname === '/me' || location.pathname === '/login' ? (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106.75 134.25" className="w-8 h-8">
                      <defs>
                        <style>
                          {`
          .str0 {stroke:black;stroke-width:9.42;stroke-miterlimit:22.9256}
          .fil0 {fill:white}
        `}
                        </style>
                      </defs>
                      <g id="Слой_x0020_1">
                        <g id="_2822300270368">
                          <path
                            className="fill-white stroke-black"
                            strokeWidth="9.42"
                            strokeMiterlimit="22.9256"
                            d="M79.6 30.94c0,14.48 -11.74,26.23 -26.23,26.23 -14.49,0 -26.23,-11.74 -26.23,-26.23 0,-14.49 11.74,-26.23 26.23,-26.23 14.49,0 26.23,11.74 26.23,26.23z"
                          />
                          <path
                            className="fill-white stroke-black"
                            strokeWidth="9.42"
                            strokeMiterlimit="22.9256"
                            d="M53.37 68.1c-26.88,0 -48.66,21.79 -48.66,48.67 0,7.05 5.72,12.77 12.77,12.77l71.79 0c7.05,0 12.77,-5.72 12.77,-12.77 0,-26.88 -21.79,-48.67 -48.67,-48.67z"
                          />
                        </g>
                      </g>
                    </svg>
                  ) : (
                    <svg className="w-8 h-8 text-customGray" viewBox="0 0 87.92 112.76">
                      <g>
                        <path
                          className="fill-current"
                          d="M67.66 23.7c0,13.08 -10.61,23.69 -23.7,23.69 -13.09,0 -23.69,-10.61 -23.69,-23.69 0,-13.09 10.61,-23.7 23.69,-23.7 13.09,0 23.7,10.61 23.7,23.7z"
                        />
                        <path
                          className="fill-current"
                          d="M43.96 57.26c-24.28,0 -43.96,19.68 -43.96,43.96 0,6.37 5.16,11.54 11.54,11.54h64.85c6.37,0 11.54,-5.16 11.54,-11.54 0,-24.28 -19.68,-43.96 -43.96,-43.96z"
                        />
                      </g>
                    </svg>
                  )}
                </li>
                {authUser ? (
                  <p
                    className={'text-md mt-1 font-sans hover:cursor-pointer text-black'}
                    onClick={() => navigate('/me')}
                  >
                    Кабинет
                  </p>
                ) : (
                  <p
                    className={'text-md mt-1 font-sans hover:cursor-pointer text-black'}
                    onClick={() => navigate('/login')}
                  >
                    Войти
                  </p>
                )}
              </div>
              <div
                className={'flex flex-col items-center hover:cursor-pointer'}
                onClick={() => navigate('/favourites')}
              >
                <li className={'flex items-center'}>
                  {location.pathname === '/favourites' ? (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 171.45 140.01" className="w-8 h-8">
                      <defs>
                        <style>
                          {`
          .str0 {stroke:black;stroke-width:10.05;stroke-miterlimit:22.9256}
          .fil0 {fill:none}
        `}
                        </style>
                      </defs>
                      <g id="Слой_x0020_1">
                        <path
                          className="fill-none stroke-black"
                          strokeWidth="10.05"
                          strokeMiterlimit="22.9256"
                          d="M85.73 134.98c-4.22,0 -8.43,-1.62 -11.66,-4.83l-57.43 -57.43c-15.49,-15.48 -15.49,-40.59 0,-56.07 7.72,-7.78 17.91,-11.63 28.05,-11.63 10.14,0 20.33,3.85 28.05,11.63l12.98 12.95 12.27 -12.27c7.96,-7.97 18.57,-12.04 29.09,-12.04 10,0 19.95,3.66 27.58,11.18 7.87,7.78 11.75,17.97 11.75,28.24 0,10.12 -3.84,20.28 -11.61,28.02l-57.43 57.43c-3.22,3.21 -7.44,4.83 -11.66,4.83z"
                        />
                      </g>
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlSpace="preserve"
                      width="32.6266mm"
                      height="26.2717mm"
                      className="w-8 h-8"
                      viewBox="0 0 150.93 121.54"
                    >
                      <defs>
                        <style>{`
                    .fil0 { fill: #rgba(0, 0, 0, 1); }
                `}</style>
                      </defs>
                      <g id="Слой_x0020_1">
                        <path
                          className="fill-current text-customGray"
                          d="M75.47 121.54c-3.94,0 -7.89,-1.52 -10.9,-4.52l-53.71 -53.71c-14.49,-14.48 -14.49,-37.96 0,-52.44 7.22,-7.27 16.75,-10.88 26.23,-10.88 9.48,0 19.01,3.6 26.23,10.88l12.14 12.11 11.48 -11.48c7.44,-7.45 17.37,-11.26 27.21,-11.26 9.35,0 18.65,3.43 25.79,10.45 7.36,7.27 10.99,16.81 10.99,26.41 0,9.46 -3.59,18.96 -10.86,26.2l-53.71 53.71c-3.01,3 -6.96,4.52 -10.9,4.52z"
                        />
                      </g>
                    </svg>
                  )}
                </li>
                <p className={'text-md font-medium mt-1 font-sans hover:cursor-pointer text-black '}>Избранное</p>
              </div>

              <div className={'flex flex-col items-center hover:cursor-pointer'} onClick={() => navigate('/basket')}>
                <li className={'flex items-center'}>
                  {location.pathname === '/basket' ? (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89.86 84.85" className="w-8 h-8">
                      <defs>
                        <style>
                          {`
          .str0 {stroke:black;stroke-width:6.37;stroke-miterlimit:22.9256}
          .fil0 {fill:none}
        `}
                        </style>
                      </defs>
                      <g id="Слой_x0020_1">
                        <path
                          className="fill-none stroke-black"
                          strokeWidth="6.37"
                          strokeMiterlimit="22.9256"
                          d="M3.19 5.98c0,5.24 5.53,2.9 8.69,3.65 0.9,0.21 1.19,0.51 1.71,1.07 0.52,0.56 0.66,1.19 0.92,2.04 3.09,10.22 5.89,21.26 8.93,31.32 0.92,3.03 1.64,5.94 2.56,9.02 0.79,2.62 1.65,6.59 2.91,8.48 1.31,1.96 4.3,4.13 7.59,4.13l35.92 0c1.82,0 3.57,-0.88 4.74,-1.64 3.41,-2.2 3.81,-5.08 4.51,-8.49l4.08 -21.79c0.69,-3.57 1.97,-6.77 -0.68,-8.28 -1.29,-0.74 -9.78,-0.38 -11.65,-0.38 -16.12,0 -32.24,0 -48.37,0 -1.12,-2.27 -3.86,-15 -5.91,-17.87 -0.9,-1.25 -2.1,-2.31 -3.52,-3.02 -2.81,-1.4 -6.03,-0.97 -9.64,-0.97 -1.25,0 -2.81,1.51 -2.81,2.73zm54.59 69.04c0,6.81 7.4,8.33 11,4.84 3.6,-3.5 2.03,-10.69 -4.98,-10.69 -3.1,0 -6.02,2.84 -6.02,5.85zm-19.27 0c0,6.81 7.4,8.33 11,4.84 3.6,-3.5 2.03,-10.69 -4.98,-10.69 -3.1,0 -6.02,2.84 -6.02,5.85z"
                        />
                      </g>
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlSpace="preserve"
                      width="26.6239mm"
                      height="25.0267mm"
                      className="w-8 h-8  "
                      viewBox="0 0 77.18 72.55"
                    >
                      <defs>
                        <style>{`
                    .fil0 { fill: customGray; }
                `}</style>
                      </defs>
                      <g id="Слой_x0020_1">
                        <path
                          className="fill-current text-customGray " //
                          d="M-0 2.58c0,4.84 5.11,2.68 8.03,3.37 0.83,0.2 1.1,0.47 1.58,0.99 0.48,0.52 0.61,1.1 0.85,1.88 2.86,9.45 5.45,19.66 8.26,28.95 0.85,2.8 1.51,5.49 2.37,8.34 0.73,2.42 1.52,6.09 2.69,7.84 1.21,1.82 3.98,3.82 7.02,3.82l33.21 0c1.68,0 3.3,-0.82 4.38,-1.51 3.15,-2.04 3.52,-4.69 4.17,-7.85l3.77 -20.14c0.64,-3.3 1.82,-6.26 -0.63,-7.66 -1.19,-0.68 -9.04,-0.35 -10.77,-0.35 -14.9,0 -29.81,0 -44.71,0 -1.04,-2.1 -3.57,-13.87 -5.46,-16.52 -0.83,-1.16 -1.94,-2.14 -3.25,-2.79 -2.6,-1.29 -5.58,-0.9 -8.91,-0.9 -1.16,0 -2.6,1.4 -2.6,2.53zm50.46 63.82c0,6.29 6.84,7.7 10.16,4.47 3.32,-3.23 1.87,-9.88 -4.6,-9.88 -2.86,0 -5.57,2.63 -5.57,5.41zm-17.81 0c0,6.29 6.84,7.7 10.16,4.47 3.32,-3.23 1.87,-9.88 -4.6,-9.88 -2.86,0 -5.57,2.63 -5.57,5.41z"
                        />
                      </g>
                    </svg>
                  )}
                </li>
                <p className={'text-md font-sans mt-1 hover:cursor-pointer text-black'}>Корзина</p>
              </div>
            </ul>
          </div>
        </div>
      </header>
    </>
  );
};

export default DesktopHeader;
