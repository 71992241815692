import React, { useState, useEffect } from 'react';
import DesktopHeader from './Headers/DesktopHeader';
import MobileHeader from "./Headers/MobileHeader";


const Header = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1100);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1100);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
      <>
        {isMobile ? <MobileHeader /> : <DesktopHeader />}
      </>
  );
};

export default Header;