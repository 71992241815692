import React, { useContext, useState, useEffect } from 'react';
import { AuthUserContext } from "../../contexts/AuthUserContext";
import { useNavigate } from "react-router-dom";
import DataComponent from "./components/DataComponent";
import OrdersComponent from "./components/OrdersComponent";
import ReviewsComponent from "./components/ReviewsComponent";
import {AnimatePresence, motion} from "framer-motion";

const Me = () => {
    const { authUser, setAuthUser } = useContext(AuthUserContext);
    const navigate = useNavigate();

    const [activeComponent, setActiveComponent] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1025);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleClick = (component) => {
        setActiveComponent(component);
    };

    const handleLogout = () => {
        localStorage.removeItem("user");
        setAuthUser(null);
        navigate('/');
    };

    const MobileVersion = () => (
        <div className={'w-full h-screen overflow-hidden flex items-center justify-center'}>
            <div className={'w-full lg:w-[80%] min-h-1/2 flex flex-col items-center'}>
                {
                    activeComponent && (
                        <div className="border-2 border-black p-2 rounded-full relative left-12 -top-36 responsive-div hover:cursor-pointer" onClick={() => setActiveComponent(null)}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                                <path
                                    fillRule="evenodd"
                                    d="M11.03 3.97a.75.75 0 0 1 0 1.06l-6.22 6.22H21a.75.75 0 0 1 0 1.5H4.81l6.22 6.22a.75.75 0 1 1-1.06 1.06l-7.5-7.5a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 0 1 1.06 0Z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </div>
                    )
                }
                <div className={`w-full h-full ${activeComponent ? 'hidden' : ''}`}>
                    <h1
                        className={'text-black font-sans text-lg font-medium hover:cursor-pointer flex flex-start ml-10 sm:ml-0'}
                        onClick={() => navigate('/')}
                    >
                        Главная
                    </h1>

                    <div className={'w-full h-[90%] flex flex-col justify-center items-start ml-10 sm:ml-0'}>
                        <span
                            className={`text-black font-sans text-lg font-medium mt-5 hover:cursor-pointer ${activeComponent === 'Data' ? 'font-bold' : ''}`}
                            onClick={() => handleClick('Data')}
                        >
                            Личные данные
                        </span>
                        <span
                            className={`text-black font-sans text-lg font-medium mt-5 hover:cursor-pointer ${activeComponent === 'Orders' ? 'font-bold' : ''}`}
                            onClick={() => handleClick('Orders')}
                        >
                            Мои заказы
                        </span>
                        <span
                            className={`text-black font-sans text-lg font-medium mt-5 hover:cursor-pointer ${activeComponent === 'Reviews' ? 'font-bold' : ''}`}
                            onClick={() => handleClick('Reviews')}
                        >
                            Отзывы
                        </span>

                        <span
                            className={'text-black font-sans text-lg font-medium mt-5 hover:cursor-pointer'}
                            onClick={() => navigate('/favourites')}
                        >
                            Избранное
                        </span>
                    </div>

                    <h1
                        className={'text-black font-sans text-lg font-bold hover:cursor-pointer mt-16 flex flex-start ml-10 sm:ml-0'}
                        onClick={() => handleLogout()}
                    >
                        Выйти
                    </h1>
                </div>

                <div className="w-full flex flex-col gap-4 mb-4">
                    <AnimatePresence>
                        {activeComponent === 'Data' && (
                            <motion.div
                                key="data"
                                initial={{ opacity: 0, x: -20 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: 20 }}
                                className="w-full h-full"
                            >
                                <DataComponent />
                            </motion.div>
                        )}
                        {activeComponent === 'Orders' && (
                            <motion.div
                                key="orders"
                                initial={{ opacity: 0, x: -20 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: 20 }}
                                className="w-full h-full"
                            >
                                <OrdersComponent />
                            </motion.div>
                        )}
                        {activeComponent === 'Reviews' && (
                            <motion.div
                                key="reviews"
                                initial={{ opacity: 0, x: -20 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: 20 }}
                                className="w-full h-full"
                            >
                                <ReviewsComponent />
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            </div>
        </div>
    );

    const DesktopVersion = () => (
        <div className={'w-full h-screen overflow-hidden flex items-center justify-center'}>
            <div className={'w-[80%] min-h-1/2 flex md:flex-row flex-col items-center'}>
                <div className={`w-full md:w-[30%] h-full`}>
                    <h1
                        className={'text-black text-lg font-medium flex items-start hover:cursor-pointer'}
                        onClick={() => navigate('/')}
                    >
                        Главная
                    </h1>

                    <div className={'w-full h-[90%] flex flex-col justify-center'}>
                        <span
                            className={`text-black text-lg font-medium mt-5 hover:cursor-pointer flex items-start ${activeComponent === 'Data' ? 'font-bold' : ''}`}
                            onClick={() => handleClick('Data')}
                        >
                            Личные данные
                        </span>
                        <span
                            className={`text-black text-lg font-medium mt-5 hover:cursor-pointer flex items-start ${activeComponent === 'Orders' ? 'font-bold' : ''}`}
                            onClick={() => handleClick('Orders')}
                        >
                            Мои заказы
                        </span>
                        <span
                            className={`text-black text-lg font-medium mt-5 hover:cursor-pointer flex items-start ${activeComponent === 'Reviews' ? 'font-bold' : ''}`}
                            onClick={() => handleClick('Reviews')}
                        >
                            Отзывы
                        </span>

                        <span
                            className={'text-black text-lg font-medium mt-5 hover:cursor-pointer flex items-start'}
                            onClick={() => navigate('/favourites')}
                        >
                            Избранное
                        </span>
                    </div>

                    <h1
                        className={'text-black text-lg font-medium flex items-start hover:cursor-pointer mt-16'}
                        onClick={() => handleLogout()}
                    >
                        Выйти
                    </h1>
                </div>

                <div className="w-full flex flex-col gap-4 mb-4">
                    <AnimatePresence>
                        {!activeComponent && (
                            <>
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -20 }}
                                    className="hidden bg-gray-300 md:flex items-center justify-center h-44 border-2 border-black rounded-3xl text-3xl font-medium"
                                >
                                    БАННЕР РЕКЛАМЫ
                                </motion.div>
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -20 }}
                                    className="hidden bg-gray-300 md:flex items-center justify-center h-44 border-2 border-black rounded-3xl text-3xl font-medium"
                                >
                                    БАННЕР РЕКЛАМЫ
                                </motion.div>
                            </>
                        )}

                        {activeComponent === 'Data' && (
                            <motion.div
                                key="data"
                                initial={{ opacity: 0, x: -20 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: 20 }}
                                className="w-full h-full"
                            >
                                <DataComponent />
                            </motion.div>
                        )}
                        {activeComponent === 'Orders' && (
                            <motion.div
                                key="orders"
                                initial={{ opacity: 0, x: -20 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: 20 }}
                                className="w-full h-full"
                            >
                                <OrdersComponent />
                            </motion.div>
                        )}
                        {activeComponent === 'Reviews' && (
                            <motion.div
                                key="reviews"
                                initial={{ opacity: 0, x: -20 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: 20 }}
                                className="w-full h-full"
                            >
                                <ReviewsComponent />
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            </div>
        </div>
    );

    return isMobile ? <MobileVersion /> : <DesktopVersion />;
};

export default Me;