import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthUserContext } from '../../contexts/AuthUserContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosClient from "../../config/axios-interceptors";

const Login = () => {
    const { authUser, setAuthUser } = useContext(AuthUserContext);
    const [phone, setPhone] = useState('+7'); // Начальное значение
    const [verifyCode, setVerifyCode] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [sended, setSended] = useState(false);
    const [lastSentTime, setLastSentTime] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const storedLastSentTime = localStorage.getItem('lastSentTime');
        const storedSended = localStorage.getItem('sended');

        if (storedLastSentTime) {
            setLastSentTime(parseInt(storedLastSentTime, 10));
        }

        if (storedSended === 'true') {
            setSended(true);
        }
    }, []);

        const handleClick = async () => {
        if (phone.length <= 2) { // Убедимся, что номер не только +7
            return false;
        }

        const currentTime = Date.now();
        const timeSinceLastSend = currentTime - (lastSentTime || 0);
        const cooldownPeriod = 60000; // 1 минута (60000 миллисекунд)

        if (timeSinceLastSend < cooldownPeriod) {
            toast.error('Пожалуйста, подождите перед повторной отправкой');
            return false;
        }

        setDisabled(true);
        try {
            const response = await axiosClient.post('/verify', { phone: phone });
            if (response) {
                setSended(true);
                setVerifyCode('');
                setLastSentTime(currentTime);
              
            } else {
                throw new Error();
            }
        } catch (error) {
            toast.error('Ошибка при отправке кода');
        } finally {
            setDisabled(false);
        }
    };

    const handleCodeVerifyClick = async () => {
        if (verifyCode.length <= 0) {
            toast.error('Пожалуйста, введите код подтверждения');
            return false;
        }
        setDisabled(true);
        try {
            const response = await axiosClient.post('/login', { phone: phone, code: verifyCode });
            if (response.data && response.data.token) {
                const userData = {
                    id: response.data.user_id,
                    phone: phone,
                    token: response.data.token
                };
                localStorage.setItem('user', JSON.stringify(userData));
                setAuthUser(userData);
                toast.success('Успешный вход');
                navigate('/');
            } else {
                throw new Error('Token not received');
            }
        } catch (error) {
            toast.error('Ошибка при проверке кода');
        } finally {
            setDisabled(false);
        }
    };

    const handlePhoneChange = (e) => {
        const value = e.target.value;
        // Убедимся, что номер всегда начинается с +7
        if (value.startsWith('+7')) {
            setPhone(value);
        }
    };

    return (
        <div className="w-full h-96 flex items-center justify-center">
            <div className="w-[75%] flex items-center justify-center">
                {sended === false ? (
                    <div className="w-full md:w-1/3 flex flex-col">
                        <div className="flex">
                            <p className="text-customGray text-2xl font-bold ml-2">Войти</p>
                        </div>

                        <input
                            className="border-black px-8 py-3 rounded-2xl border-2 text-black focus:outline-none text-lg mt-2"
                            value={phone}
                            onChange={handlePhoneChange}
                            maxLength="12"
                        />

                        <button
                            disabled={disabled}
                            onClick={handleClick}
                            className={`px-10 py-3 text-xl text-customGray font-bold rounded-xl mt-2 ${disabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-customGray bg-opacity-35 text-customGray'}`}
                        >
                            Получить код
                        </button>

                        <span className="text-customGray text-sm mt-2">
                            Нажимая «Получить код», я соглашаюсь с условиями участия в ALMARAY, политикой конфиденциальности и подтверждаю согласие на получение сообщений рекламного характера. Отказаться от рассылки можно в личном кабинете.
                        </span>
                    </div>
                ) : (
                    <div className="w-full md:w-1/3 flex flex-col">
                        <div className="flex">
                            <p className="text-customGray text-2xl font-bold ml-6">Введите код</p>
                        </div>

                        <input
                            type="password"
                            className="border-black px-8 py-3 rounded-2xl border-2 text-black focus:outline-none text-lg mt-2"
                            value={verifyCode}
                            onChange={(e) => setVerifyCode(e.target.value)}
                        />

                        <button
                            disabled={disabled}
                            onClick={handleCodeVerifyClick}
                            className={`px-10 py-3 text-lg text-customGray font-bold rounded-lg mt-2 ${disabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-customGray bg-opacity-35 text-customGray'}`}
                        >
                            Отправить код
                        </button>
                    </div>
                )}

                <div className="w-1/2  hidden md:flex items-center justify-center ml-28">
                    <img src={`${process.env.PUBLIC_URL}/ALMARAY.png`} alt="Описание изображения" className="mx-auto my-4" />
                </div>
            </div>
        </div>
    );
};

export default Login;
