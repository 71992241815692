import React, {useEffect, useLayoutEffect, useContext, useState} from 'react';
import Product from './components/Product';
import {SearchContext} from '../../contexts/SearchContext';
import Loader from '../../components/Loader';
import {CatalogContext} from '../../contexts/CatalogContext';
import ProductHat from './components/ProductHat';
import ProductsSidebarWrapper from './components/ProductsSidebarWrapper';
import {useLocation} from 'react-router-dom';
import useProductsStore from '../../store/ProductStore';

const Products = () => {
    const {
        products,
        filteredProducts,
        loading,
        sidebarLoading,
        sort,
        currentPage,
        totalPages,
        minPrice,
        maxPrice,
        selecteds,
        goldType,
        totalProducts,
        setProducts,
        setFilteredProducts,
        setLoading,
        setSidebarLoading,
        setSort,
        setCurrentPage,
        setTotalPages,
        setMinPrice,
        setMaxPrice,
        setSelecteds,
        setGoldType,
        setTotalProducts,
        setCatalogId,
        fetchProducts,
        inserts,
        setInserts
    } = useProductsStore();

    const {searchValue} = useContext(SearchContext);
    const {catalogId} = useContext(CatalogContext);
    const location = useLocation();

    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => setIsOpen(!isOpen);

    const materials = ['Красное золото', 'Желтое золото'];
    const uniqueInserts = ['Фианит', 'Сапфир', 'Рубин', 'Изумруд', 'Гранат'];

    useLayoutEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, [location]);

    const handlePriceChange = (newPrices) => {
        const [newMinPrice, newMaxPrice] = newPrices;
        setMinPrice(newMinPrice);
        setMaxPrice(newMaxPrice);
    };

    const handleFilterProducts = (filtered) => {
        setFilteredProducts(filtered);
    };

    useEffect(() => {
        setCatalogId(catalogId);
        setCurrentPage(1);
    }, [catalogId]);

    useEffect(() => {
        fetchProducts(currentPage);
        setIsOpen(false);
    }, [currentPage, catalogId, sort, minPrice, maxPrice, selecteds, goldType, inserts]);

    useEffect(() => {
        setFilteredProducts(products);
    }, [products]);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    const isLoading = loading || sidebarLoading;

    return (
      <div className={'w-full flex items-center justify-center'}>
        <div className="flex flex-col lg:flex-row w-full 2xl:w-[90%]">
          <ProductsSidebarWrapper
            setMaxPrice={setMaxPrice}
            setMinPrice={setMinPrice}
            minPrice={minPrice}
            maxPrice={maxPrice}
            selecteds={selecteds}
            setSelecteds={setSelecteds}
            onPriceChange={handlePriceChange}
            products={products}
            onFilterProducts={handleFilterProducts}
            setLoading={setSidebarLoading}
            goldType={goldType}
            setGoldType={setGoldType}
            totalProducts={totalProducts}
            catalogId={catalogId}
            inserts={inserts}
            setInserts={setInserts}
          />
          <div className="w-full lg:w-[100%] flex flex-col items-center justify-center lg:justify-start">
              <ProductHat
                  toggleDropdown={toggleDropdown}
                  isOpen={isOpen}
                  products={products}
                  sort={sort}
                  setSort={setSort}
                  selecteds={selecteds}
                  setSelecteds={setSelecteds}
                  goldType={goldType}
                  setGoldType={setGoldType}
                  minPrice={minPrice}
                  maxPrice={maxPrice}
                  setMinPrice={setMinPrice}
                  setMaxPrice={setMaxPrice}
                  onPriceChange={handlePriceChange}
                  inserts={inserts}
                  setInserts={setInserts}
              />

            {isLoading ? (
              <Loader />
            ) : (
              <div className="w-full grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {filteredProducts.length > 0 ? (
                  filteredProducts.map((product) => <Product key={product.id} product={product} />)
                ) : (
                  <div className="w-full h-[50%] col-span-full text-center text-gray-500">Продукты не найдены</div>
                )}
              </div>
            )}
          </div>
        </div>
        {!isLoading && (
          <div className="fixed right-5 top-[75%] lg:top-[60%] transform -translate-y-1/2 flex flex-col items-center">
            <nav className="relative z-0 inline-flex flex-col rounded-md " aria-label="Pagination">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="w-11 h-11 flex items-center justify-center border border-black rounded-xl mb-2 hover:bg-gray-200 hover:cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 23.87 28.66"
                  className="fill-current text-black size-6"
                >
                  <path d="M11.88 0c0.9,0 1.27,0.52 1.92,1.16 1.13,1.13 9.29,9.22 9.61,9.64 0.79,1.02 0.49,1.72 -0.38,2.59 -0.63,0.63 -1.34,1.35 -2.32,0.89 -0.63,-0.3 -5.92,-5.97 -6.54,-6.39 0,0.96 0.05,18.92 -0.01,19.42 -0.07,0.61 -0.77,1.35 -1.45,1.35l-1.62 0c-0.68,0 -1.33,-0.72 -1.39,-1.35 -0.06,-0.55 -0.01,-7.36 -0.01,-8.28 0,-3.69 0,-7.39 0,-11.08 -0.26,0.18 -5.25,5.22 -5.83,5.81 -1.2,1.21 -2.02,0.63 -2.99,-0.34 -1.51,-1.51 -0.81,-2.24 0.28,-3.33 1.13,-1.13 9.25,-9.32 9.67,-9.64 0.3,-0.23 0.53,-0.45 1.06,-0.45z" />
                </svg>
              </button>

              <div className="flex flex-col items-center mb-2">
                <span className="text-xs text-gray-500">Стр</span>
                <span className="mx-2 text-lg text-black font-bold">{currentPage}</span>
                <span className="text-xs text-gray-500">
                  из <span className="">{totalPages}</span>
                </span>
              </div>

                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="w-11 h-11 flex items-center justify-center border border-black rounded-xl hover:bg-gray-200 hover:cursor-pointer"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 23.87 28.66"
                        className="fill-current text-black size-6"
                    >
                        <path
                            d="M11.88 28.66c0.9,0 1.27,-0.52 1.92,-1.16 1.13,-1.13 9.29,-9.22 9.61,-9.64 0.79,-1.02 0.49,-1.72 -0.38,-2.59 -0.63,-0.63 -1.34,-1.35 -2.32,-0.89 -0.63,0.3 -5.92,5.97 -6.54,6.39 0,-0.96 0.05,-18.92 -0.01,-19.42 -0.07,-0.61 -0.77,-1.35 -1.45,-1.35l-1.62 0c-0.68,0 -1.33,0.72 -1.39,1.35 -0.06,0.55 -0.01,7.36 -0.01,8.28 0,3.69 0,7.39 0,11.08 -0.26,-0.18 -5.25,-5.22 -5.83,-5.81 -1.2,-1.21 -2.02,-0.63 -2.99,0.34 -1.51,1.51 -0.81,2.24 0.28,3.33 1.13,1.13 9.25,9.32 9.67,9.64 0.3,0.23 0.53,0.45 1.06,0.45z"
                        />
                    </svg>
                </button>
            </nav>
          </div>
        )}
      </div>
    );
};

export default Products;