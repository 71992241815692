import React from 'react';

const Man = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width="20.9873mm"
        height="17.9983mm"
        version="1.1"
        viewBox="0 0 43.65 37.43"
        className="size-5"
    >
        <g id="Слой_x0020_1">
            <g id="_2821914769360">
                <path
                    className="fill-current"
                    d="M15.52 4.2l0 10.32c0,0.57 0.24,1.1 0.82,1.1l10.96 0c0.58,0 0.82,-0.52 0.82,-1.1l0 -10.32c0,-0.71 -0.28,-1.1 -1,-1.1l-10.59 0c-0.72,0 -1,0.39 -1,1.1z"
                />
                <path
                    className="fill-current"
                    d="M23.37 35.79c-5.21,0 -9.74,-0.13 -14.76,-1.58 -2.6,-0.75 -7.74,-2.58 -6.93,-6.05 0.92,-3.95 9.96,-5.59 13.85,-5.97 1.82,-0.17 2.82,-0.28 4.65,-0.28 1.54,0 0.79,-0.14 2.38,-0 0.25,0.02 0.56,0.01 0.82,0 4.88,-0.01 17.46,1.42 18.59,6.25 0.81,3.46 -4.33,5.29 -6.93,6.05 -3.3,0.96 -8.09,1.58 -11.65,1.58zm-23.37 -7.12c0,2.18 1.56,3.55 3.12,4.55 5.16,3.3 12.85,4.22 19.43,4.22 5.71,0 13.19,-1.23 17.91,-4.18 4.19,-2.62 4.26,-5.97 0.12,-8.74 -4.24,-2.84 -12.12,-4.24 -17.67,-4.24 -5.43,0 -10.11,0.36 -14.78,1.93 -1.83,0.62 -3.52,1.29 -5.01,2.29 -1.3,0.87 -3.12,2.32 -3.12,4.18z"
                />
                <path
                    className="fill-current"
                    d="M3.1 28.85c0,2.36 5.65,3.91 7.54,4.33 7.01,1.54 15.34,1.54 22.35,0 2.04,-0.45 9.96,-2.6 6.79,-5.77 -1.43,-1.43 -1.4,-0.92 -2.96,0.01 -3.84,2.3 -9.53,3.71 -14.18,3.71 -4.39,0 -6.52,-0.24 -10.47,-1.4 -1.78,-0.53 -3.81,-1.39 -5.36,-2.31 -1.51,-0.9 -1.52,-1.44 -2.92,-0.05 -0.37,0.36 -0.8,0.79 -0.8,1.49z"
                />
                <path
                    className="fill-current"
                    d="M14.06 14.79l0 -10.86c0,-0.65 0.24,-1.18 0.57,-1.53 0.26,-0.28 0.86,-0.75 1.35,-0.75l11.5 0c1.15,0 2.1,1.09 2.1,2.28l0 10.86c0,1.49 -1.13,2.37 -2.65,2.37l-10.23 0c-1.52,0 -2.65,-0.88 -2.65,-2.37zm-1.64 -12.14l0 13.6c0,0.71 0.27,1.27 0.62,1.67 0.33,0.38 0.91,0.8 1.58,0.8l14.42 0c0.67,0 1.24,-0.42 1.58,-0.8 0.4,-0.46 0.62,-1.02 0.62,-1.85l0 -13.42c0,-1.53 -0.95,-2.65 -2.37,-2.65l-14.06 0c-1.42,0 -2.37,1.11 -2.37,2.65z"
                />
                <path
                    className="fill-current"
                    d="M36.61 21c0.87,0.2 1.61,0.62 2.36,0.93 0.28,0.11 0.52,0.28 0.83,0.35l0 -14.24c0,-0.59 0.11,-0.84 -0.2,-1.17 -0.47,-0.51 -2.38,-2.04 -3,-2.21l0 16.34z"
                />
                <path
                    className="fill-current"
                    d="M3.83 22.28c0.55,-0.13 1.02,-0.45 1.53,-0.67 0.51,-0.22 1.14,-0.49 1.67,-0.61l0 -16.34c-0.57,0.15 -2.58,1.76 -3,2.21 -0.34,0.36 -0.2,1.5 -0.2,2.36l0 13.06z"
                />
                <path
                    className="fill-current"
                    d="M31.13 19.54l3.93 0.91 0 -16.71c-0.49,-0.26 -1.86,-0.88 -2.37,-1l0 13.7c0,1.88 -1.15,2.49 -1.56,3.1z"
                />
                <path
                    className="fill-current"
                    d="M8.58 3.74l0 16.71 3.93 -0.91c-0.37,-0.55 -1.54,-1.23 -1.56,-3.01l0 -13.79 -2.37 1z"
                />
                <path
                    className="fill-current"
                    d="M41.27 23.19c1.25,0.84 2.14,1.76 2.37,1.92 0,-3.1 -0,-6.21 0,-9.31 0,-1.47 -0.08,-2.68 -0.61,-4.05 -0.33,-0.86 -1.13,-2.56 -1.76,-2.98l0 14.43z"
                />
                <path
                    className="fill-current"
                    d="M0 25.11l1.12 -0.98c0.4,-0.38 0.81,-0.64 1.26,-0.94l0 -14.43c-0.63,0.43 -1.41,2.1 -1.74,2.92 -0.46,1.15 -0.64,2.39 -0.64,4.02 0,3.13 0,6.27 0,9.4z"
                />
            </g>
        </g>
    </svg>
);

export default Man;
