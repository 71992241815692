import React, { useEffect, useState } from 'react';
import axiosClient from "../../../config/axios-interceptors";

const BasketEditModal = ({ modalItemId, onClose, onUpdate }) => {
    const [item, setItem] = useState({});
    const [selectedSize, setSelectedSize] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [sizes, setSizes] = useState([]);

    useEffect(() => {
        axiosClient.get(`/basket/${modalItemId}`)
            .then(res => {
                console.log('API response:', res.data);  // Debug log
                setItem(res.data);
                setSelectedSize(res.data.size || '');
                setQuantity(Number(res.data.quantity) || 1);

                if (res.data.product_sizes) {
                    setSizes(parseSizes(res.data.product_sizes));
                } else {
                    console.warn('product_sizes is undefined in API response');
                    if (res.data.size) {
                        setSizes([res.data.size]);
                    } else {
                        setSizes([]);
                    }
                }
            })
            .catch(error => {
                console.error('Error fetching item:', error);
            });
    }, [modalItemId]);

    const parseSizes = (sizeRange) => {
        sizeRange = sizeRange.replace(/,/g, '.');
        const [min, max] = sizeRange.split('-').map(Number);
        const sizesArray = [];
        for (let i = min; i <= max; i += 0.5) {
            sizesArray.push(i.toFixed(1));
        }
        return sizesArray;
    };

    const handleUpdate = async () => {
        try {
            const response = await axiosClient.put(`/basket/${modalItemId}`, {
                size: selectedSize,
                quantity: quantity
            });
            console.log('Update response:', response.data);
            onUpdate(response.data);
            onClose();
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };

    return (
        <div className="p-6">
            <h2 className="text-xl font-bold mb-4">Размер</h2>
            <div className="flex flex-wrap mb-4">
                {sizes.length > 0 ? (
                    sizes.map((size) => (
                        <button
                            key={size}
                            className={`mr-2 mb-2 px-3 py-2 border text-gray-600 border-gray-500 rounded-xl
                                ${selectedSize === size ? 'bg-gray-300' : 'bg-white'}`}
                            onClick={() => setSelectedSize(size)}
                        >
                            {size}
                        </button>
                    ))
                ) : (
                    <p>Размеры недоступны для этого товара</p>
                )}
            </div>

            <h2 className="text-xl font-bold mb-4">Количество</h2>
            <div className="flex items-center mb-4">
                <button
                    className="p-1 rounded-xl border-2 border-black"
                    onClick={() => setQuantity((prev) => Math.max(1, prev - 1))}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="size-6"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                    </svg>
                </button>
                <span className="mx-4 border-2 border-black px-16 py-1 rounded-2xl"> <span className={"font-bold text-lg"}>{quantity}</span></span>
                <button className="p-1 rounded-xl border-2 border-black" onClick={() => setQuantity((prev) => prev + 1)}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="size-6"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                    </svg>
                </button>
            </div>

            <div className={'w-full flex items-center justify-center mt-10'}>
                <div
                    className="text-amber-50 flex rounded-2xl hover:cursor-pointer items-center justify-center w-1/2 p-2 bg-customGray"
                    onClick={handleUpdate}
                >
                    Обновить
                </div>
            </div>
        </div>
    );
};

export default BasketEditModal;