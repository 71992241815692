import React, {useEffect, useState} from 'react';
import {motion} from "framer-motion";
import axiosClient from "../../../config/axios-interceptors";
import Loader from "../../../components/Loader";

const BasketEditModalMobile = ({onClose, onUpdate, modalItemId}) => {
    const [item, setItem] = useState({});
    const [selectedSize, setSelectedSize] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [sizes, setSizes] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true)
        axiosClient.get(`/basket/${modalItemId}`)
            .then(res => {
                console.log('API response:', res.data);  // Debug log
                setItem(res.data);
                setSelectedSize(res.data.size || '');
                setQuantity(Number(res.data.quantity) || 1);

                if (res.data.product_sizes) {
                    setSizes(parseSizes(res.data.product_sizes));
                } else {
                    console.warn('product_sizes is undefined in API response');
                    if (res.data.size) {
                        setSizes([res.data.size]);
                    } else {
                        setSizes([]);
                    }
                }
            })
            .catch(error => {
                console.error('Error fetching item:', error);
            });
        setLoading(false)

    }, [modalItemId]);

    const parseSizes = (sizeRange) => {
        sizeRange = sizeRange.replace(/,/g, '.');
        const [min, max] = sizeRange.split('-').map(Number);
        const sizesArray = [];
        for (let i = min; i <= max; i += 0.5) {
            sizesArray.push(i.toFixed(1));
        }
        return sizesArray;
    };

    const handleUpdate = async () => {
        try {
            const response = await axiosClient.put(`/basket/${modalItemId}`, {
                size: selectedSize,
                quantity: quantity
            });
            console.log('Update response:', response.data);
            onUpdate(response.data);
            onClose();
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };

    if (loading) return <Loader />;


    return (
      <motion.div
        initial={{ opacity: 0, y: '100%' }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: '100%' }}
        transition={{ type: 'spring', damping: 25, stiffness: 500 }}
        className="inset-0 bg-white z-50"
      >
        <div className={'flex items-center justify-start ml-4 mb-3'}>

        </div>
        <div className="flex items-center justify-center p-4 mt-10">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="w-full max-w-sm rounded-lg mx-auto"
          >
            <div className="">
              <h2 className="text-xl font-bold mb-4">Размер</h2>
              <div className="grid grid-cols-5 sm:grid-cols-6 mb-4">
                {sizes.length > 0 ? (
                  sizes.map((size) => (
                    <button
                      key={size}
                      className={`mr-2 mb-2 px-2 py-2 border text-black border-black rounded-lg
                                ${selectedSize === size ? 'bg-black text-white' : 'bg-white'}`}
                      onClick={() => setSelectedSize(size)}
                    >
                      {size}
                    </button>
                  ))
                ) : (
                  <p>Размеры недоступны для этого товара</p>
                )}
              </div>

              <h2 className="text-xl font-bold mb-4">Количество</h2>
              <div className="flex items-center mb-4">
                <button
                  className="p-1 rounded-xl border-2 border-black"
                  onClick={() => setQuantity((prev) => Math.max(1, prev - 1))}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                  </svg>
                </button>
                <span className="mx-4 border-2 border-black px-[94px] py-1 rounded-2xl">
                  {' '}
                  <span className={'font-bold text-lg'}>{quantity}</span>
                </span>
                <button
                  className="p-1 rounded-xl border-2 border-black"
                  onClick={() => setQuantity((prev) => prev + 1)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                  </svg>
                </button>
              </div>

              <div className={'w-full flex items-center justify-center mt-10'}>
                <motion.button
                  whileHover={{ backgroundColor: '#333' }}
                  whileTap={{ scale: 0.95 }}
                  className="w-full bg-black text-white py-3 rounded-lg text-md font-bold"
                  onClick={() => handleUpdate()}
                >
                  Сохранить изменения
                </motion.button>
              </div>
            </div>
          </motion.div>
        </div>
      </motion.div>
    );
};

export default BasketEditModalMobile;