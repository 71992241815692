import React, {useContext} from 'react';
import {CatalogContext} from "../../../contexts/CatalogContext";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";

const ProductHat = ({
                        isOpen,
                        toggleDropdown,
                        products,
                        sort,
                        setSort,
                        selecteds,
                        setSelecteds,
                        goldType,
                        setGoldType,
                        minPrice,
                        maxPrice,
                        setMinPrice,
                        setMaxPrice,
                        onPriceChange,
                        inserts,
                        setInserts
                    }) => {
    const catalogName = products && products.length > 1 ? products[0].catalog_name : '';
    const { catalogId, setCatalogId } = useContext(CatalogContext);

    const catalogNames = {
        1: 'Браслеты',
        2: 'Кольца',
        3: 'Серьги',
        4: 'Цепи',
        null: 'Все'
    };

    const goldNames = {
        yellowgold: 'Желтое золото',
        redgold: 'Красное золото'
    };

    const handleDragStart = (e, index) => {
        e.dataTransfer.setData('text/plain', index);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e, dropIndex) => {
        e.preventDefault();
        const dragIndex = Number(e.dataTransfer.getData('text'));
        if (dragIndex === dropIndex) return;

        const newSelecteds = [...selecteds];
        const [reorderedItem] = newSelecteds.splice(dragIndex, 1);
        newSelecteds.splice(dropIndex, 0, reorderedItem);
        setSelecteds(newSelecteds);
    };

    return (
        <div className="w-full min-h-[100px] mt-2 md:mt-10 ml-0 md:ml-4">
            <div className="w-full h-1/2 flex flex-col sm:flex-row justify-end">
                <div className="relative inline-block text-left w-full lg:w-1/4 xl:w-1/5">
                    <div className={"font-sans"}>
                        <button
                            type="button"
                            className="inline-flex justify-between w-full font-sans rounded-xl text-xl lg:text-2xl border border-black shadow-sm px-3 py-2 bg-white font-bold text-customGray hover:bg-gray-50 focus:outline-none"
                            id="options-menu"
                            aria-haspopup="true"
                            aria-expanded="true"
                            onClick={() => toggleDropdown()}
                        >
                            Сортировать
                            <div className={"relative lg:top-1"}>
                                <MdKeyboardArrowDown className={"size-7 text-black"}/>
                            </div>
                        </button>
                    </div>

                    {isOpen && (
                        <div
                            className="origin-top-right absolute right-0 mt-2 border border-customGray w-full lg:w-full rounded-xl shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-40">
                            <div className="py-1 p-1" role="menu" aria-orientation="vertical"
                                 aria-labelledby="options-menu">
                                <button
                                    className={`block rounded-full px-4 text-sm text-customGray w-full text-left ${sort === 'popularity' ? 'bg-gray-200' : ''}`}
                                    role="menuitem"
                                    onClick={() => setSort('popularity')}>
                                    По популярности
                                </button>
                                <button
                                    className={`block rounded-full px-4 text-sm text-customGray w-full text-left ${sort === 'new' ? 'bg-gray-200' : ''}`}
                                    role="menuitem"
                                    onClick={() => setSort('new')}>
                                    Новинки
                                </button>
                                <button
                                    className={`block rounded-full px-4 text-sm text-customGray w-full text-left ${sort === 'price_asc' ? 'bg-gray-200' : ''}`}
                                    role="menuitem"
                                    onClick={() => setSort('price_asc')}>
                                    По возрастанию цены
                                </button>
                                <button
                                    className={`block rounded-full px-4 text-sm text-customGray w-full text-left ${sort === 'price_desc' ? 'bg-gray-200' : ''}`}
                                    role="menuitem"
                                    onClick={() => setSort('price_desc')}>
                                    По убыванию цены
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="w-full flex mr-5 overflow-x-auto">
                <div className="w-4/5 flex items-center mt-5 md:mt-0">
                    {selecteds.map((selected, index) => (
                        <div
                            key={index}
                            className="bg-customGray text-white rounded-xl text-xl px-4 py-2 mr-2 mb-2 flex items-center"
                        >
                            <span>{selected}</span>
                            <button onClick={() => {
                                const newSelecteds = selecteds.filter(item => item !== selected);
                                setSelecteds(newSelecteds);
                            }} className="ml-2">×</button>
                        </div>
                    ))}
                    {inserts.map((insert, index) => (
                        <div
                            key={index}
                            className="bg-customGray text-white rounded-xl text-xl px-4 py-2 mr-2 mb-2 flex items-center"
                        >
                            <span>{insert}</span>
                            <button onClick={() => {
                                const newInserts = inserts.filter(item => item !== insert);
                                setInserts(newInserts);
                            }} className="ml-2">×</button>
                        </div>
                    ))}
                    {goldType && (
                        <div className="bg-customGray text-white rounded-xl text-xl px-4 py-2 mr-2 mb-2 flex items-center">
                            <span>{goldNames[goldType]}</span>
                            <button onClick={() => setGoldType('')} className="ml-2">×</button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProductHat;