import React, { useContext, useEffect, useState } from "react";
import Loader from "../components/Loader";
import {AuthUserContext} from "../contexts/AuthUserContext";

const AuthLoader = ({ children }) => {
    const { setAuthUser } = useContext(AuthUserContext);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const user = localStorage.getItem("user");
        if (user) {
            setAuthUser(JSON.parse(user));
        }
        setLoading(false);
    }, [setAuthUser]);

    if (loading) {
        return <Loader/>
    }

    return children;
};

export default AuthLoader;
