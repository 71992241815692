import React from 'react';

const Podveski = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width="20.8678mm"
        height="22.097mm"
        version="1.1"
        viewBox="0 0 43.03 45.57"
        className="size-5"
    >
        <g id="Слой_x0020_1">
            <g id="_2821866347904">
                <path
                    className="fill-current"
                    d="M18.72 20.79l-0.01 -3.46c-5.6,-0.93 -9.43,-3.94 -11.78,-7.07 -3.94,-5.23 -2.83,-8.77 -4.2,-9.89 -1.11,-0.91 -2.75,-0.06 -2.74,1.44 0.02,2.05 1.37,5.6 2.19,7.18 3.28,6.36 9.33,10.88 16.54,11.79z"
                />
                <path
                    className="fill-current"
                    d="M17.38 37.93c0,0.38 1.62,1.17 2.02,1.4 2.69,1.56 1.74,1.56 4.42,0 0.41,-0.23 2.01,-1 2.01,-1.4l-0 -7.11c-0.34,-0.27 -4.05,-2.4 -4.23,-2.4 -0.17,-0 -3.84,2.11 -4.22,2.4l-0 7.11z"
                />
                <path
                    className="fill-current"
                    d="M21.13 16.34c-0.94,0.24 -0.79,0.77 -0.79,1.51l-0 3.99c0.04,0.63 0.71,1.12 1.79,0.85 0.91,-0.23 0.76,-0.88 0.76,-1.52 -0,-0.66 -0,-1.33 -0,-1.99 0,-0.97 0.55,-3.42 -1.76,-2.84z"
                />
                <path
                    className="fill-current"
                    d="M12.83 32.69l0 2.63c1.45,0.29 0.51,2.31 0.83,4.77 0.3,-0.24 1.91,-1.58 2.03,-1.86 0.13,-0.3 0.17,-7.23 -0.03,-7.62 -0.16,-0.3 -1.71,-1.41 -1.98,-1.75 -0.4,1.78 0.59,3.53 -0.85,3.82z"
                />
                <path
                    className="fill-current"
                    d="M29.58 28.89c-0.32,0.23 -1.99,1.6 -2.08,1.9 -0.11,0.39 -0.04,3.16 -0.04,3.79 0,5.08 -0.45,2.98 2.11,5.49l0 -11.18z"
                />
                <path
                    className="fill-current"
                    d="M22.42 45.57c0.71,-0.32 6.33,-3.55 6.56,-3.83l-1.83 -1.62c-0.21,-0.18 -0.36,-0.28 -0.6,-0.51l-4.13 2.35 -0 3.6z"
                />
                <path
                    className="fill-current"
                    d="M22.42 26.73c0,0.38 0.61,0.57 0.96,0.77 0.64,0.38 2.75,1.65 3.2,1.78l2.41 -2.14 -6.57 -3.76 -0 3.35z"
                />
                <path
                    className="fill-current"
                    d="M16.64 29.27c0.49,-0.13 1.67,-0.9 2.16,-1.19 0.56,-0.33 1.98,-0.97 1.98,-1.35l-0.02 -3.35 -6.51 3.8 2.38 2.1z"
                />
                <path
                    className="fill-current"
                    d="M20.79 45.56l-0.02 -3.56 -4.13 -2.32c-0.34,0.31 -0.84,0.77 -1.22,1.07 -0.42,0.33 -0.78,0.72 -1.2,1.05l6.56 3.76z"
                />
                <path
                    className="fill-current"
                    d="M24.31 20.79l0.01 -3.46c5.6,-0.93 9.43,-3.94 11.78,-7.07 3.94,-5.23 2.83,-8.77 4.2,-9.89 1.11,-0.91 2.75,-0.06 2.74,1.44 -0.02,2.05 -1.37,5.6 -2.19,7.18 -3.29,6.36 -9.33,10.88 -16.54,11.79z"
                />
            </g>
        </g>
    </svg>
);

export default Podveski;
