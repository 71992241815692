const Kolcca = () => (
    <svg
        className="size-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 28.64 35.18"
        fill="none"
    >
        <path
            className="fill-current"
            d="M0 20.86c0,6.8 4.79,12.44 11.07,13.92 0.97,0.23 2.06,0.33 3.2,0.4l1.63 -0.11c2.92,-0.4 5.27,-1.35 7.54,-3.19 5.65,-4.57 6.95,-13.09 2.6,-19.21 -1.73,-2.44 -3.45,-3.72 -6,-4.94l-3.12 3.16c0.63,0.16 1.21,0.36 1.74,0.63 2.7,1.36 4.59,3.27 5.51,6.35 0.35,1.17 0.58,2.79 0.36,4.22 -0.15,0.95 -0.36,1.96 -0.74,2.79 -0.5,1.12 -1.53,2.79 -2.44,3.49 -0.44,0.34 -0.48,0.5 -1.29,1.04 -4.61,3.11 -10.65,2.03 -14.12,-2.57 -0.3,-0.4 -0.72,-1.08 -0.93,-1.54 -0.86,-1.94 -0.99,-2.81 -0.99,-5.01 0,-2.1 1.1,-4.62 2.38,-6.09 1.39,-1.59 3.11,-2.81 5.24,-3.3 -0.19,-0.29 -0.49,-0.49 -0.74,-0.82 -0.44,-0.57 -1.93,-1.81 -2.3,-2.36 -0.3,0.16 -0.61,0.25 -0.91,0.43 -1.95,1.13 -3.8,2.57 -5.06,4.47 -1.65,2.49 -2.64,5.14 -2.64,8.23z"
        />
        <path className="fill-current" d="M14.26 10.91l0.14 0c0.03,-0.32 0.91,-2.85 1.09,-3.36l1.1 -3.34 -4.52 0 1.1 3.34c0.18,0.53 1.06,3.04 1.09,3.36z"/>
        <path className="fill-current" d="M12.99 10.56c-0.03,-0.32 -1.36,-4.1 -1.58,-4.77 -0.16,-0.48 -0.35,-1.18 -0.54,-1.58l-4.16 0c0.09,0.33 2.79,2.9 3.09,3.19 0.48,0.48 2.89,2.95 3.19,3.16z"/>
        <path className="fill-current" d="M15.67 10.56c0.38,-0.25 1.19,-1.15 1.57,-1.54l3.95 -3.95c0.23,-0.23 0.67,-0.55 0.76,-0.86l-4.16 0 -2.12 6.35z"/>
        <path className="fill-current" d="M12.28 3.22c4.85,0 4.44,0.26 4.09,-0.7l-0.56 -1.69c-0.33,-0.99 0.14,-0.79 -2.53,-0.79 -0.26,0 -0.53,1.14 -0.68,1.58 -0.25,0.75 -0.72,1.6 -0.31,1.6z"/>
        <path className="fill-current" d="M17.79 3.22l4.16 0c-0.09,-0.36 -2.99,-3.12 -3.12,-3.16 -0.22,-0.06 -1.75,-0.02 -2.1,-0.02 0.04,0.42 0.38,1.16 0.52,1.6 0.16,0.48 0.35,1.18 0.54,1.58z"/>
        <path className="fill-current" d="M6.71 3.22l4.16 0 1.06 -3.18c-2.67,0 -1.65,-0.5 -4.22,2.06 -0.24,0.24 -0.93,0.84 -1.01,1.11z"/>
    </svg>
);

export default Kolcca;
