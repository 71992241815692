import React, {useEffect, useRef, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import Checkbox from './ProductSidebarComponents/Checkbox';
import Slider from 'react-slider';
import {toast} from 'react-toastify';

const ProductSidebarMobileContent = ({
                                         setMaxPrice,
                                         setMinPrice,
                                         minPrice,
                                         maxPrice,
                                         selecteds,
                                         setSelecteds,
                                         onPriceChange,
                                         products,
                                         onFilterProducts,
                                         setLoading,
                                         goldType,
                                         setGoldType,
                                         totalProducts,
                                         isModalOpen,
                                         setIsModalOpen,
                                         catalogId
                                     }) => {
    const [materialOpen, setMaterialOpen] = useState(true);
    const [priceOpen, setPriceOpen] = useState(true);
    const [subtypeOpen, setSubtypeOpen] = useState(true);
    const [sizeOpen, setSizeOpen] = useState(true);
    const [sizes, setSizes] = useState([]);
    const [localMinPrice, setLocalMinPrice] = useState(minPrice);
    const [localMaxPrice, setLocalMaxPrice] = useState(maxPrice);
    const debounceTimeout = useRef(null);

    useEffect(() => {
        extractSizes(products);
    }, [products]);

    useEffect(() => {
        return () => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
        };
    }, []);

    const extractSizes = (products) => {
        const sizeSet = new Set();
        products.forEach((product) => {
            if (product.size) {
                const [min, max] = product.size.split('-').map((s) => parseFloat(s.trim()));
                if (!isNaN(min) && !isNaN(max)) {
                    for (let i = min; i <= max; i += 0.5) {
                        sizeSet.add(i);
                    }
                }
            }
        });
        setSizes(Array.from(sizeSet).sort((a, b) => a - b));
    };

    const handleSliderChange = (value) => {
        setLocalMinPrice(value[0]);
        setLocalMaxPrice(value[1]);
    };

    const handleSliderAfterChange = (value) => {
        setMinPrice(value[0]);
        setMaxPrice(value[1]);
        setLoading(true);
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            onPriceChange(value);
            setLoading(false);
        }, 500);
    };

    const handleCheckboxChange = (value) => {
        if (selecteds.length >= 10 && !selecteds.includes(value)) {
            toast.info('Вы не можете выбрать больше 10 элементов');
            return;
        }
      const isNumeric = !isNaN(parseFloat(value)) && isFinite(value);

      const newValue = isNumeric ? parseFloat(value) : value;

      const newSelecteds = selecteds.includes(newValue)
          ? selecteds.filter((item) => item !== newValue)
          : [...selecteds, newValue];

      setSelecteds(newSelecteds);
    };

    const handleGoldTypeChange = (type) => {
        setGoldType(type === goldType ? '' : type);
    };


    useEffect(() => {
        switch (catalogId) {
            case 1:
                setSizes(['16', '16.5', '17', '17.5', '18', '18.5', '19', '19.5', '20', '20.5', '21', '21.5', '22']);
                break
            case 2:
                setSizes(['16', '16.5', '17', '17.5', '18', '18.5', '19', '19.5', '20', '20.5', '21', '21.5', '22']);
                break;
            case 3:
                setSizes(['17.5', '18', '18.5', '19', '19.5', '20', '20.5', '21']);
                break;
            case 4:
                setSizes(['50', '50.5', '51', '51.5', '52', '52.5', '53', '53.5', '54', '54.5', '55', '55.5', '56', '56.5', '57', '57.5', '58', '58.5', '59', '59.5', '60']);
                break;
        }
    }, [catalogId]);

    const uniqueInserts = ['Фианит', 'Сапфир', 'Рубин', 'Изумруд', 'Гранат'];
    const materials = ['Красное золото', 'Желтое золото', 'Белое золото'];

    const handleClear = () => {
        setSelecteds([]);
        setGoldType('');
        setMinPrice(21500);
        setMaxPrice(644500);
        setLocalMinPrice(21500);
        setLocalMaxPrice(644500);
    };

    return (
        <motion.div
            className="p-0 mx-4 mt-10 lg:mt-[70px] sm:mx-0 sm:p-4 min-w-[20%] bg-white z-50"
            initial={{y: '100%', opacity: 0}}
            animate={{y: 0, opacity: 1}}
            exit={{y: '100%', opacity: 0}}
            transition={{duration: 0.3}}
        >
      <span className={'absolute -top-[38px] right-0 text-customGray hover:text-black'} onClick={() => handleClear()}>
        Очистить
      </span>
            <div className="mb-6 lg:mt-8">
                <span className={'text-customGray text-xl'}>Материал</span>
                <div className="flex flex-wrap">
                    <label
                        className={`flex pr-2 py-1 border border-black rounded-xl mr-2 my-2 items-center mb-2 ${goldType === 'redgold' ? 'bg-black' : ''}`}
                        onClick={() => handleGoldTypeChange('redgold')}
                    >
            <span className={`text-gray-600 text-md ml-2 ${goldType === 'redgold' ? 'text-white' : ''}`}>
              Красное золото
            </span>
                    </label>
                    <label
                        className={`flex pr-2 py-1 border border-black rounded-xl mr-2 my-2 items-center mb-2 ${goldType === 'yellowgold' ? 'bg-black' : ''}`}
                        onClick={() => handleGoldTypeChange('yellowgold')}
                    >
            <span className={`text-gray-600 text-md ml-2 ${goldType === 'yellowgold' ? 'text-white' : ''}`}>
              Желтое золото
            </span>
                    </label>
                </div>
            </div>

            <div className="mb-6 w-full sm:w-2/3 md:mt-8">
                <button
                    onClick={() => setSubtypeOpen(!subtypeOpen)}
                    className="flex items-center w-full text-xl font-semibold mb-2"
                >
                    <span className={'text-customGray font-medium text-xl mr-2'}>Вставка</span>
                </button>
                {subtypeOpen && (
                    <div className="flex flex-wrap">
                        {uniqueInserts.map((insert) => (
                            <label
                                className={`flex pr-2 py-1 border border-black rounded-xl mr-2 my-2 items-center mb-2 ${selecteds.includes(insert) ? 'bg-black' : ''}`}
                                key={insert}
                                onClick={() => handleCheckboxChange(insert)}
                            >
                <span className={`text-gray-600 text-md ml-2 ${selecteds.includes(insert) ? 'text-white' : ''}`}>
                  {insert}
                </span>
                            </label>
                        ))}
                    </div>
                )}
            </div>

            <div className="mb-6">
                <button
                    onClick={() => setPriceOpen(!priceOpen)}
                    className="flex items-center w-full text-xl font-semibold mb-2"
                >
                    <h1 className="text-customGray font-medium text-xl mr-2">Цена</h1>
                </button>
                {priceOpen && (
                    <div className={'w-full sm:w-2/3 ml-0'}>
                        <Slider
                            className="w-full h-[2px] flex items-center bg-black rounded-lg mt-6"
                            thumbClassName="size-5 bg-black rounded-full cursor-pointer"
                            trackClassName="bg-black"
                            value={[localMinPrice, localMaxPrice]}
                            min={21500}
                            max={644000}
                            step={1000}
                            onChange={handleSliderChange}
                            onAfterChange={handleSliderAfterChange}
                        />

                        <div className="flex w-full mt-2">
                            <div className={'flex items-center justify-between w-full'}>
                                <div
                                    className="w-full mr-2 mt-2 border border-black rounded-xl p-2 pl-6 text-sm"
                                    style={{outline: 'none', textAlign: 'left'}}
                                >
                                    <span style={{fontSize: '12px'}}>от </span>
                                    <span style={{fontSize: '17px'}}>{localMinPrice}</span>
                                    <span style={{fontSize: '12px'}}> ₸</span>
                                </div>
                                <div
                                    className="w-full ml-2 mt-2 border border-black rounded-xl p-2 pl-6 text-sm"
                                    style={{outline: 'none', textAlign: 'left'}}
                                >
                                    <span style={{fontSize: '12px'}}>от </span>
                                    <span style={{fontSize: '17px'}}>{localMaxPrice}</span>
                                    <span style={{fontSize: '12px'}}> ₸</span>
                                </div>
                            </div>
                        </div>

                    </div>
                )}
            </div>

            <div className="mb-6">
                <button onClick={() => setSizeOpen(!sizeOpen)}
                        className="flex items-center w-full text-xl font-semibold mb-2">
                    <span className={'text-customGray font-medium text-xl mr-2'}>Размер</span>
                </button>
                {sizeOpen && (
                    <div className="w-[100%] sm:w-2/3 ml-0 flex flex-wrap gap-1">
                        {sizes.map((size) => (
                            <label
                                className={`min-w-12 sm:min-w-16 flex items-center justify-center pr-2 py-1 sm:py-1.5 border border-black rounded-lg mr-2 mb-1 ${selecteds.includes(size) ? 'bg-black' : ''}`}
                                key={size}
                                onClick={() => handleCheckboxChange(size)}
                            >
                <span className={`text-gray-600 text-sm ml-2 ${selecteds.includes(size) ? 'text-white' : ''}`}>
                  {size}
                </span>
                            </label>
                        ))}
                    </div>
                )}
            </div>

            <motion.button
                whileHover={{backgroundColor: ''}}
                whileTap={{scale: 0.95}}
                className="w-full bg-black text-white py-3 rounded-lg text-md font-bold hover:text-gray-300 transition-colors duration-300"
                onClick={() => setIsModalOpen(!isModalOpen)}
            >
                Показать товары ({totalProducts})
            </motion.button>
        </motion.div>
    );
};

export default ProductSidebarMobileContent;
