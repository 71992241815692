const Braslety = () => (
    <svg
        className="size-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 43.38 43.28"
        fill="none"
    >
        <path
            className="fill-current"
            d="M14.49 9.22l5.4 5.44c0.32,-0.1 0.79,-0.59 1.09,-0.82 0.39,-0.31 0.7,-0.56 1.08,-0.87 1.86,-1.51 7.59,-5.09 9.53,-3.06 0.27,0.29 0.09,0.31 0.44,0.4 0.07,-0.28 1.1,-2.27 1.43,-3.15 0.78,-2.11 1.84,-5.88 -1.1,-6.93 -3.47,-1.23 -9.58,2.49 -12.52,4.66l-5.35 4.31"
        />
        <path
            className="fill-current"
            d="M0 30.57c0,1.11 0.12,2.02 0.89,2.84 1.5,1.63 4.67,0.63 6.3,0.03 0.88,-0.33 2.87,-1.36 3.15,-1.43l0 -0.17c-0.94,-0.25 -1.02,-1.54 -1.02,-2.37 0,-0.87 0.49,-2.21 0.79,-2.85 0.68,-1.47 1.19,-2.18 2.01,-3.42 0.45,-0.68 2.31,-2.89 2.37,-3.13l-5.4 -5.44c-0.15,0.21 -0.23,0.26 -0.37,0.43l-0.7 0.82c-0.15,0.19 -0.22,0.23 -0.36,0.4 -1.59,1.88 -2.66,3.35 -4.02,5.38 -1.26,1.88 -3.63,5.94 -3.63,8.91z"
        />
        <path
            className="fill-current"
            d="M35.22 17.16l1.78 1.73 -1.74 1.78 -1.78 -1.73 1.74 -1.78zm8.15 -4.96l0 1.06c-0.12,1.39 -0.68,2.83 -1.21,4.06 -0.62,1.45 -0.77,1.59 -1.44,2.79 -0.72,1.3 -4.4,6.62 -5.31,7.23 0,-0.96 -0.45,-2.09 -0.79,-2.77 -1.16,-2.31 -3.62,-4.09 -6.58,-4.09 0.07,-0.26 0.17,-0.3 0.34,-0.51 1.45,-1.83 2.27,-2.69 3.71,-4.84 0.22,-0.33 0.4,-0.59 0.62,-0.9 0.2,-0.29 0.4,-0.66 0.58,-0.95 1.33,-2.22 3.39,-6.25 3.39,-8.98 0.37,0.1 0.73,0.6 1,0.87l4.53 4.53c0.73,0.73 1.08,1.59 1.16,2.5zm-8.07 1.36l1.73 -1.78 1.78 1.74 -1.78 1.82 -1.73 -1.78z"
        />
        <path
            className="fill-current"
            d="M13.54 35.29l1.74 1.69 -1.78 1.82 -1.69 -1.74 1.74 -1.78zm3.64 -0.04l1.69 -1.73 1.78 1.73 -1.74 1.78 -1.74 -1.78zm-5.4 8.03l2.12 0c1.82,0 5.55,-2.08 7.34,-3.17 0.65,-0.4 5.41,-3.71 5.97,-4.54 -1.83,0 -3.65,-1.16 -4.81,-2.31 -1.08,-1.07 -2.05,-2.94 -2.05,-5.06 -0.26,0.07 -2.47,1.93 -2.71,2.12 -0.45,0.34 -0.87,0.69 -1.34,1.03l-2.91 1.92c-2.14,1.28 -4.21,2.38 -6.66,3 -2.84,0.72 -3.01,-0.01 -1.56,1.44l3.64 3.64c0.7,0.7 1.69,1.93 2.99,1.93z"
        />
        <path
            className="fill-current"
            d="M22.79 28.03c0,4.48 5.43,6.9 8.71,3.62 0.69,-0.69 1.1,-1.36 1.38,-2.43 0.75,-2.89 -1.48,-6.27 -5,-6.27 -2.78,0 -5.08,2.31 -5.08,5.08z"
        />
    </svg>
);

export default Braslety;
