import React from 'react';
import { useNavigate } from "react-router-dom";

const Main = () => {
    const navigate = useNavigate()
    return (
        <div className="w-full min-h-screen flex flex-col">
            <div className="w-full py-4 md:py-8 flex items-center justify-center">
                <div className="w-[75%]">
                    <img src={`${process.env.PUBLIC_URL}/ALMARAY.png`} alt="ALMARAY logo" className="w-full" />
                </div>
            </div>

            <div className="hidden relative sm:block w-full">
                <img src={`${process.env.PUBLIC_URL}/сайт.jpg`} alt="Site background"
                     className="w-full h-full" />

                {/*<button*/}
                {/*    className="absolute bottom-8 left-[250px] transform -translate-x-1/2*/}
                {/*               bg-customGray px-6 py-2 rounded-2xl text-lg font-bold text-amber-50*/}
                {/*               hover:bg-opacity-90 transition-colors duration-300"*/}
                {/*    onClick={() => navigate('/products')}>*/}
                {/*    Перейти*/}
                {/*</button>*/}
            </div>
        </div>
    );
};

export default Main;