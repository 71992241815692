import React from 'react';

const Premium = () => (
    <svg
        width="20.122mm"
        height="20.122mm"
        className={"size-5"}
        version="1.1"
        style={{ shapeRendering: "geometricPrecision", textRendering: "geometricPrecision", imageRendering: "optimizeQuality", fillRule: "evenodd", clipRule: "evenodd" }}
        viewBox="0 0 40.04 40.04"
    >
        <g id="Слой_x0020_1">
            <g id="_2821856047120">
                <path className="fill-current text-black" d="M21.59 40.04l18.32 -18.28 -12.61 -0.02 -3.57 11.37c-0.23,0.79 -0.52,1.54 -0.73,2.32l-1.07 3.39c-0.1,0.3 -0.32,0.96 -0.34,1.22z" />
                <path className="fill-current text-black" d="M19.94 36.91l0.16 0c0.04,-0.46 0.4,-1.43 0.54,-1.89 0.19,-0.63 0.4,-1.25 0.61,-1.9 0.42,-1.29 0.8,-2.56 1.19,-3.81l2.36 -7.58 -9.54 0 1.16 3.76c0.81,2.39 1.56,5.16 2.37,7.57l1.16 3.84z" />
                <path className="fill-current text-black" d="M4.38 25.97c0.24,-2.84 3.95,-3.51 5.23,-1.16 0.63,1.15 -0.03,1.78 0.97,2.78 0.35,0.35 0.58,0.51 1.18,0.61 0.84,0.14 0.85,-0.02 1.54,0.34 2.6,1.37 1.53,5.18 -1.1,5.24 0.23,0.35 4.65,4.69 5.42,5.46 0.25,0.25 0.48,0.63 0.84,0.72l-5.71 -18.22 -12.59 0c0.07,0.28 0.26,0.38 0.45,0.57l3.77 3.66z" />
                <path className="fill-current text-black" d="M28.7 19.4l11.34 0c-0.07,-0.28 -0.48,-0.79 -0.66,-1.06 -0.6,-0.89 -4.86,-7.32 -5.05,-7.46 -0.08,0.29 -4.42,6.63 -4.94,7.41 -0.18,0.27 -0.61,0.83 -0.69,1.11z" />
                <path className="fill-current text-black" d="M-0 19.4l11.34 0c-0.13,-0.48 -5.52,-8.12 -5.63,-8.52 -0.23,0.17 -3.8,5.62 -4.29,6.35l-1.42 2.17z" />
                <path className="fill-current text-black" d="M13.53 18.22c0.21,-0.32 0.4,-0.68 0.61,-1.03 0.24,-0.4 4.22,-7.08 4.32,-7.49l-10.71 0 5.02 7.49c0.19,0.29 0.52,0.87 0.77,1.03z" />
                <path className="fill-current text-black" d="M26.43 10.56c1.01,-0.53 -0.17,-1.68 1.7,-3.54 0.49,-0.49 0.84,-0.75 1.55,-1.03 1.48,-0.58 1.99,0.04 1.99,-0.82 0,-0.63 -1.2,0.01 -2.79,-1.05l-1.3 -1.28c-0.23,-0.33 -0.3,-0.51 -0.44,-0.89 -0.51,-1.36 0.01,-1.94 -0.79,-1.94 -0.36,0 -0.36,0.52 -0.39,0.86 -0.03,0.48 -0.11,0.7 -0.25,1.08 -0.37,0.99 -0.94,1.64 -1.79,2.2 -0.29,0.19 -0.52,0.29 -0.89,0.44 -1.08,0.41 -1.98,0.03 -1.88,0.72 0.11,0.82 1.68,-0.18 3.54,1.68 1.93,1.93 0.72,3.04 1.74,3.58z" />
                <path className="fill-current text-black" d="M2.11 30.58c-0.65,0.97 0.35,0.84 0.75,0.89 0.7,0.1 0.99,0.2 1.6,0.51 0.4,0.21 0.48,0.33 0.81,0.59 0.76,0.59 1.35,1.78 1.44,2.78 0.05,0.49 -0.02,0.93 0.48,0.93 0.19,0 0.23,-0.13 0.39,-0.23 0,-2.67 1.9,-4.61 4.61,-4.61 0.33,-0.49 0.33,-0.37 0,-0.86 -2.69,0 -4.61,-1.93 -4.61,-4.61 -0.36,-0.24 -0.81,-0.61 -0.86,0.54 -0.02,0.33 -0.11,0.75 -0.2,1.05 -0.23,0.72 -0.54,1.08 -0.93,1.57 -0.28,0.35 -0.95,0.83 -1.39,1.04 -0.61,0.29 -1.21,0.42 -2.09,0.42z" />
                <path className="fill-current text-black" d="M15.64 19.4l8.76 0c-0.08,-0.36 -1.81,-3.15 -2.14,-3.73 -0.37,-0.66 -0.73,-1.22 -1.09,-1.88l-1.23 -1.9 -2.69 4.67c-0.19,0.34 -0.35,0.59 -0.55,0.93l-1.06 1.91z" />
                <path className="fill-current text-black" d="M26.51 18.22c0.24,-0.16 0.54,-0.69 0.74,-0.99l5.05 -7.54 -3.05 0c0,0.97 -0.24,1.73 -0.72,2.25 -1.7,1.83 -4.91,0.99 -4.91,-2.25l-2.03 0c0.1,0.42 2.17,3.78 2.45,4.28l2.48 4.25z" />
            </g>
        </g>
    </svg>
);

export default Premium;
