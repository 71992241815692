import React from 'react';

const InstagramIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 79.45 79.45"
            className="size-9"
        >
            <rect
                y="0"
                width="79.45"
                height="79.45"
                rx="8.99"
                ry="8.99"
                className="fill-customGray"
            />
            <g>
                <path
                    d="M52.13 24.66c-1.52,0 -2.75,1.23 -2.75,2.75 0,1.52 1.23,2.75 2.75,2.75 1.52,0 2.75,-1.23 2.75,-2.75 0,-1.52 -1.23,-2.75 -2.75,-2.75z"
                    className="fill-white"
                />
                <path
                    d="M39.92 47.12c-4.08,0 -7.39,-3.32 -7.39,-7.39 0,-4.08 3.32,-7.39 7.39,-7.39 4.08,0 7.39,3.32 7.39,7.39 0,4.08 -3.32,7.39 -7.39,7.39zm0 -18.93c-6.36,0 -11.54,5.18 -11.54,11.54 0,6.36 5.18,11.54 11.54,11.54 6.36,0 11.54,-5.18 11.54,-11.54 0,-6.36 -5.18,-11.54 -11.54,-11.54z"
                    className="fill-white"
                />
                <path
                    d="M30.37 20.71c-5.33,0 -9.67,4.34 -9.67,9.67l0 18.71c0,5.33 4.34,9.67 9.67,9.67l18.71 0c5.33,0 9.67,-4.34 9.67,-9.67l0 -18.71c0,-5.33 -4.34,-9.67 -9.67,-9.67l-18.71 0zm18.71 42.45l-18.71 0c-7.76,0 -14.07,-6.31 -14.07,-14.07l0 -18.71c0,-7.76 6.31,-14.07 14.07,-14.07l18.71 0c7.76,0 14.07,6.31 14.07,14.07l0 18.71c0,7.76 -6.31,14.07 -14.07,14.07z"
                    className="fill-white"
                />
            </g>
        </svg>
    );
};

export default InstagramIcon;
