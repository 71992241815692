import React, { useContext, useEffect, useState } from 'react';
import { CatalogContext } from '../../../contexts/CatalogContext';
import axiosClient from '../../../config/axios-interceptors';
import Loader from "../../../components/Loader";

const OrdersComponent = () => {
  const [orders, setOrders] = useState([]);
  const { isOpenCatalog, setIsOpenCatalog } = useContext(CatalogContext);
  const [loading, setLoading] = useState(true);
  const fetchOrders = async () => {
    setLoading(true)
    try {
      const response = await axiosClient.get('/orders');
      setOrders(response.data);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }

    setLoading(false)
  };


  useEffect(() => {
    fetchOrders();
  }, []);

  if (loading) return <Loader />

  return (
      <div className="w-full flex justify-center hover:cursor-pointer">
        <div className="flex flex-col w-full h-screen max-w-3xl overflow-y-auto mt-10 hide-scrollbar">
          <h1 className="text-3xl font-bold flex justify-center md:flex-start md:justify-normal mb-6">Мои заказы</h1>

          {orders.length === 0 ? (
              <h1 className="text-2xl font-medium text-gray-600 flex mt-4">Вы пока ничего не заказывали</h1>
          ) : (
              <div className="mt-4">
                {orders.map((order) => (
                    <div key={order.id} className="shadow-md border border-gray-200 rounded-2xl p-4 mb-4">
                      <div className={'flex items-center justify-between'}>
                        <h2 className="text-xl font-semibold mb-2">Заказ №{order.id}</h2>
                        <p className="mb-2">Сумма: {order.total_price} ₸</p>
                      </div>

                      <div className="flex flex-col gap-2 mt-2">
                        {order.items.map((item) => (
                            <div key={item.id} className=" p-2 rounded flex w-full">
                              <div className="w-1/2 sm:w-1/3 h-full bg-grayLight rounded-2xl">
                                <img src="/catalogImages/111001157.4.png" alt="" className="w-full h-full mb-2" />
                              </div>

                              <div className="ml-2 flex flex-col justify-center">
                                <h1 className="text-xl font-bold flex flex-start ml-6 mt-1">
                                  {item.product_name.match(/[А-Яа-яЁё]+/)[0]}
                                </h1>
                                <h1 className="text-md flex flex-start ml-6">Размер: {item.size}</h1>
                                <h1 className="text-md flex flex-start ml-6">
                                  {item.quantity} {item.quantity > 1 ? 'изделия ' : 'изделие'}
                                </h1>
                                <h1 className="text-md flex flex-start ml-6 mt-1">
                                  {item.price} ₸
                                </h1>
                                <div className={"flex flex-col sm:flex-row sm:justify-between"}>
                                  <div className="flex items-center mt-2 hover:cursor-pointer mr-3 ml-6">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                         stroke="currentColor" className="size-6 hover:fill-gray-300">
                                      <path strokeLinecap="round" strokeLinejoin="round"
                                            d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"/>
                                    </svg>
                                    <span className="text-sm text-gray-500 ml-1">Купить</span>
                                  </div>

                                  <div className="flex items-center mt-2 hover:cursor-pointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                         stroke="currentColor" className="size-6 hover:fill-gray-300 ml-6">
                                      <path strokeLinecap="round" strokeLinejoin="round"
                                            d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"/>
                                    </svg>
                                    <span className="text-sm text-gray-500 ml-1">В избранное</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                        ))}
                      </div>
                    </div>
                ))}
              </div>
          )}

          <button
              className="font-bold text-gray-500 flex items-center justify-center px-2 py-2 border-2 border-black rounded-2xl mt-4 text-lg"
              onClick={() => setIsOpenCatalog(!isOpenCatalog)}
          >
            Перейти в каталог
          </button>
        </div>
      </div>
  );
};

export default OrdersComponent;