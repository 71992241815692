import {toast} from "react-toastify";

export const FavouritesSizeModal = ({ sizes, selectedSize, setSelectedSize, setIsModalOpen, product, addToBasket }) => {
    const generateSizeRange = (sizeString) => {
        if (!sizeString) return [];
        const [minSize, maxSize] = sizeString.split(' - ').map(size => parseFloat(size.replace(',', '.')));
        const sizeRange = [];
        for (let size = minSize; size <= maxSize; size += 0.5) {
            sizeRange.push(size.toFixed(1));
        }
        return sizeRange;
    };

    const sizeRangeString = sizes && sizes.length > 0 ? sizes[0] : '';
    const sizeRange = generateSizeRange(sizeRangeString);

    const handleAddToBasket = async () => {
        try {
            if (!selectedSize) {
                toast.error('Пожалуйста, выберите размер');
                return;
            }

            await addToBasket(product.product_id, selectedSize);

            setIsModalOpen(false);
        } catch (error) {
            console.error('Error:', error);
            toast.error('Чтобы добавлять товар в корзину нужно авторизоваться!');
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="relative">
                <div
                    className="absolute top-0 right-0 m-4 hover:cursor-pointer"
                    onClick={() => setIsModalOpen(false)}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#000000"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                </div>
                <div className="bg-white px-14 py-6 rounded-lg">
                    <h2 className="text-md font-bold mb-4">Выберите размер</h2>
                    {sizeRange.length > 0 ? (
                        <div className="grid grid-cols-4 mb-4">
                            {sizeRange.map((size) => (
                                <button
                                    key={size}
                                    className={`mr-2 mb-2 px-3 py-2 border border-gray-400 text-gray-600 rounded-xl ${
                                        selectedSize === size ? 'bg-gray-300' : 'bg-white'
                                    }`}
                                    onClick={() => setSelectedSize(size)}
                                >
                                    {size}
                                </button>
                            ))}
                        </div>
                    ) : (
                        <p>Размеры не доступны</p>
                    )}
                    <div className="flex justify-end">
                        <button
                            className="px-4 py-2 bg-gray-200 rounded-md mr-2"
                            onClick={() => setIsModalOpen(false)}
                        >
                            Отмена
                        </button>
                        <button
                            className="px-4 py-2 bg-customGray text-white rounded-md"
                            onClick={handleAddToBasket}
                        >
                            Добавить в корзину
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};