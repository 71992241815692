import React, {useState, useEffect} from 'react';
import ProductsSidebar from './ProductsSidebar';
import ProductSidebarMobile from './ProductSidebarMobile';

const ProductsSidebarWrapper = ({
                                    setMaxPrice,
                                    setMinPrice,
                                    minPrice,
                                    maxPrice,
                                    selecteds,
                                    setSelecteds,
                                    onPriceChange,
                                    products,
                                    onFilterProducts,
                                    setLoading,
                                    goldType,
                                    setGoldType,
                                    totalProducts,
                                    catalogId,
                                    inserts,
                                    setInserts
                                }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1020);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1020);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            {isMobile ? (
                <ProductSidebarMobile
                    setMaxPrice={setMaxPrice}
                    setMinPrice={setMinPrice}
                    minPrice={minPrice}
                    maxPrice={maxPrice}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    onPriceChange={onPriceChange}
                    products={products}
                    onFilterProducts={onFilterProducts}
                    setLoading={setLoading}
                    goldType={goldType}
                    setGoldType={setGoldType}
                    totalProducts={totalProducts}
                    catalogId={catalogId}
                />
            ) : (
                <ProductsSidebar
                    setMaxPrice={setMaxPrice}
                    setMinPrice={setMinPrice}
                    minPrice={minPrice}
                    maxPrice={maxPrice}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    onPriceChange={onPriceChange}
                    products={products}
                    onFilterProducts={onFilterProducts}
                    setLoading={setLoading}
                    goldType={goldType}
                    setGoldType={setGoldType}
                    catalogId={catalogId}
                    inserts={inserts}
                    setInserts={setInserts}
                />
            )}
        </>
    );
};

export default ProductsSidebarWrapper;
