import { useState } from 'react';
import { toast } from 'react-toastify';
import axiosClient from "../config/axios-interceptors";

const useAddToBasket = () => {
    const [selectedSize, setSelectedSize] = useState('');

    const handleBasketClick = async (product_id, size) => {
        if (size && !selectedSize) {
            toast.error('Пожалуйста, выберите размер');
            return;
        }
        try {
            toast.success('Добавлено в корзину');
            const response = await axiosClient.post('/basket', {
                product_id: product_id,
                quantity: 1,
                size: selectedSize,
            });
            console.log(response.data);
        } catch (error) {
            console.error('Error:', error);
            toast.error('Ошибка, слишком много запросов!');
        }
    };

    return {
        selectedSize,
        setSelectedSize,
        handleBasketClick,
    };
};

export default useAddToBasket;