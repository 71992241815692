import { toast } from "react-toastify";
import axiosClient from "../config/axios-interceptors";
import React from "react";

export const SizeModal = ({ sizes, selectedSize, setSelectedSize, setIsModalOpen, product }) => {
    const handleAddToBasket = async () => {
        if (!selectedSize) {
            toast.error('Пожалуйста, выберите размер');
            return;
        }
        try {
            toast.success('Добавлено в корзину');
            const response = await axiosClient.post('/basket', {
                product_id: product.id,
                quantity: 1,
                size: selectedSize,
            });
            console.log(response.data);
            setIsModalOpen(false);
        } catch (error) {
            console.error('Error:', error);
            toast.error('Чтобы добавлять товар в корзину нужно авторизоваться!!');
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="relative">
                <div
                    className="absolute top-0 right-0 m-4 hover:cursor-pointer"
                    onClick={() => setIsModalOpen(false)}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#000000"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                </div>
                <div className="bg-white px-14 py-6 rounded-lg">
                    <h2 className="text-md font-bold mb-4">Выберите размер</h2>
                    <div className="grid grid-cols-4 mb-4">
                        {sizes.map((size) => (
                            <button
                                key={size}
                                className={`mr-2 mb-2 px-3 py-2 border border-gray-400 text-gray-600 rounded-xl ${
                                    selectedSize === size ? 'bg-gray-300' : 'bg-white'
                                }`}
                                onClick={() => setSelectedSize(size)}
                            >
                                {size}
                            </button>
                        ))}
                    </div>
                    <div className="flex justify-end">
                        <button
                            className="px-4 py-2 bg-gray-200 rounded-md mr-2"
                            onClick={() => setIsModalOpen(false)}
                        >
                            Отмена
                        </button>
                        <button
                            className="px-4 py-2 bg-customGray text-white rounded-md"
                            onClick={handleAddToBasket}
                        >
                            Добавить в корзину
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};