import React, { useEffect, useState, useMemo, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import BasketProduct from './components/BasketProduct';
import axiosClient from '../../config/axios-interceptors';
import Loader from '../../components/Loader';
import { useNavigate } from 'react-router-dom';
import ModalComponent from '../../components/Modal';
import Me from "../Me/Me";
import ChooseCity from "../../components/ChooseCity";
import BasketEditModal from "./components/BasketEditModal";
import { CatalogContext } from "../../contexts/CatalogContext";
import { toast } from "react-toastify";
import Modal from "../../components/SidebarModal";
import ProductSidebarMobileContent from "../Products/components/ProductSidebarMobileContent";
import BasketEditModalMobile from "./components/BasketEditModalMobile";

const Basket = () => {
  const [basket, setBasket] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState(false);
  const [modalItemId, setModalItemId] = useState(0);
  const { isOpenCatalog, setIsOpenCatalog } = useContext(CatalogContext);
  const navigate = useNavigate();
  const [isFullBlockVisible, setIsFullBlockVisible] = useState(false);
  const [city, setCity] = useState('Шымкент');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1100);
  const [showSelectedOnly, setShowSelectedOnly] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1100);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchBasket = async () => {
      try {
        setLoading(true);
        const response = await axiosClient.get('basket');
        setBasket(response.data);
        setSelectedItems(response.data.map((item) => item.id));
      } catch (error) {
        console.error('Error fetching basket:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBasket();
  }, []);

  const handleCheckboxChange = (id) => {
    setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.includes(id) ? prevSelectedItems.filter((itemId) => itemId !== id) : [...prevSelectedItems, id]
    );
  };

  const handleDelete = async (id, size) => {
    try {
      const response = await axiosClient.delete(`basket/${id}`);
      if (response.status === 200) {
        setBasket((prevBasket) => prevBasket.filter((item) => !(item.product_id === id && item.size === size)));
        setSelectedItems((prevSelected) =>
            prevSelected.filter((selectedItem) => !(selectedItem.id === id && selectedItem.size === size))
        );
      }
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const deleteAll = async () => {
    basket.forEach((item) => {
      handleDelete(item.product_id, item.size);
    });
  };

  const handleOrder = async () => {
    const storedCity = localStorage.getItem('city');

    if (!storedCity) {
      toast.error('Пожалуйста, выберите город');
      return;
    }

    const selectedProducts = basket.filter((item) => selectedItems.includes(item.id));
    try {
      const response = await axiosClient.post('/orders', {
        items: selectedProducts,
        city: storedCity,
      });
      if (response.status === 200) {
        setSelectedItems([]);
        setBasket([]);
        navigate('/orderSuccess');
      }
    } catch (error) {
      console.error('Error placing order:', error);
    }
  };

  const { totalPrice, finalPrice, selectedCount } = useMemo(() => {
    let total = 0;
    let count = 0;
    const disc = 13000;
    basket.forEach((item) => {
      if (selectedItems.includes(item.id)) {
        total += item.price * item.average_weight * item.quantity;
        count += item.quantity;
      }
    });

    return {
      totalPrice: total,
      finalPrice: total - disc,
      selectedCount: count,
    };
  }, [basket, selectedItems]);

  const updateBasketItem = (updatedItem) => {
    setBasket((prevBasket) =>
      prevBasket.map((item) => (item.id === updatedItem.id ? { ...item, ...updatedItem } : item))
    );
  };

  if (loading) return <Loader />;

  if (basket.length === 0) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <div className={'w-1/3 flex items-center justify-center ml-20'}>
          <div className={'w-full h-1/2 flex flex-col items-center'}>
            <h1 className={'text-3xl text-center text-customGray mt-2'}>В вашей корзине пусто</h1>
            <span className={'text-lg font-sans text-center text-customGray mt-2'}>добавьте украшение мечты</span>
            <button
              className="font-bold font-sans text-customGray flex items-center justify-center px-5 py-2 border-2 border-black rounded-2xl mt-4 text-2xl"
              onClick={() => setIsOpenCatalog(!isOpenCatalog)}
            >
              Перейти в каталог
            </button>
          </div>
        </div>

        <div className={'w-full md:w-1/3 ml-20 h-[400px]'}>
          <img src="/catalogImages/корзина%20иллюстрация%20(1).png" className={"h-[300px]"} />
        </div>
      </div>
    );
  }

  const FullBlock = ({ onClose }) => (
    <motion.div
      initial={{ opacity: 0, y: '100%' }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: '100%' }}
      transition={{ type: 'spring', damping: 25, stiffness: 500 }}
      className="fixed inset-0 bg-white z-50 overflow-y-auto"
    >
      <div className="container mx-auto p-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="w-full max-w-sm border border-gray-300 rounded-lg p-4 my-4 mx-auto"
        >
          <div className="flex justify-between items-center mb-4">
            <span className="text-lg font-semibold">Итого</span>
            <span className="text-lg font-semibold">{finalPrice <= 0 ? 0 : finalPrice} ₸</span>
          </div>
          <div className="flex justify-between items-center mb-2">
            <span className="text-sm text-gray-600">{selectedCount} товар на сумму</span>
            <span className="text-sm text-gray-600">{totalPrice} ₸</span>
          </div>
          <div className="flex justify-between items-center mb-4">
            <span className="text-sm text-gray-600">Скидка по акции</span>
            <span className="text-sm text-gray-600">−13000 ₸</span>
          </div>
          <motion.button
            whileHover={{ backgroundColor: '#333' }}
            whileTap={{ scale: 0.95 }}
            className="w-full bg-black text-white py-3 rounded-lg text-md font-bold"
            onClick={handleOrder}
          >
            Оформить заказ
          </motion.button>
          <motion.button
            whileHover={{ backgroundColor: '#f0f0f0' }}
            whileTap={{ scale: 0.95 }}
            className="w-full bg-white text-black py-3 rounded-lg text-md font-bold mt-2 border border-black"
            onClick={onClose}
          >
            Вернуться в корзину
          </motion.button>
        </motion.div>
      </div>
    </motion.div>
  );

  const filteredBasket = showSelectedOnly ? basket.filter(item => selectedItems.includes(item.id)) : basket;

  return (
      <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="w-full h-auto flex items-center justify-center mb-0 md:mb-20"
      >
        {isMobile ? (
            <Modal isOpen={active} onClose={() => setActive(!active)}>
              <BasketEditModalMobile onClose={() => setActive(!active)} modalItemId={modalItemId} onUpdate={updateBasketItem}/>
            </Modal>
        ) : (
            <ModalComponent
                isOpen={active}
                onRequestClose={() => setActive(!active)}
                Component={BasketEditModal}
                componentProps={{
                  modalItemId: modalItemId,
                  onClose: () => setActive(!active),
                  onUpdate: updateBasketItem
                }}
            />
        )}

        <div className={'mt-3 sm:mt-0 w-full lg:w-[80%] flex flex-col'}>
          <motion.div
              initial={{ y: -20 }}
              animate={{ y: 0 }}
              transition={{ duration: 0.5 }}
              className={'w-full flex items-center justify-between mt-5'}
          >
            <h1 className={'text-3xl font-bold'}>{filteredBasket.length} изделия</h1>
            <svg
                onClick={() => setIsOpen(!isOpen)}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="size-10 hover:cursor-pointer"
            >
              <path
                  fillRule="evenodd"
                  d="M4.5 12a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm6 0a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm6 0a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
                  clipRule="evenodd"
              />
            </svg>
          </motion.div>

          {isOpen && (
              <div className="origin-top-right relative left-0 md:left-[70%] lg:left-[80%] border-2 border-gray-400 w-full md:w-56 mt-5 md:mt-0 rounded-xl shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-40 md:mb-10 mb-0">
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  <button
                      className="block px-4 py-2 text-sm text-gray-700 w-full text-left"
                      role="menuitem"
                      onClick={() => setSelectedItems([])}
                  >
                    Снять выбор со всех
                  </button>
                  <button
                      className="block px-4 py-2 text-sm text-gray-700 w-full text-left"
                      role="menuitem"
                      onClick={() => deleteAll()}
                  >
                    Очистить корзину
                  </button>
                </div>
              </div>
          )}

          <div className={'w-full flex flex-col items-center 2xl:flex 2xl:flex-row 2xl:items-start mt-0 sm:mt-10'}>
            <div className={'w-full 2xl:w-2/3 flex flex-col mt-10 md:mt-0'}>
              <AnimatePresence>
                {filteredBasket.map((item) => (
                    <motion.div
                        key={item.id}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.3 }}
                    >
                      <BasketProduct
                          item={item}
                          onDelete={handleDelete}
                          onCheckboxChange={handleCheckboxChange}
                          isChecked={selectedItems.includes(item.id)}
                          active={active}
                          setActive={setActive}
                          modalItemId={modalItemId}
                          setModalItemId={setModalItemId}
                      />
                    </motion.div>
                ))}
              </AnimatePresence>
            </div>

            <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="w-full max-w-sm border border-gray-300 rounded-lg p-4 my-4 hidden xl:block"
            >
              <div className="flex justify-between items-center mb-4">
                <span className="text-lg font-semibold">Итого</span>
                <motion.span
                    key={finalPrice}
                    initial={{ scale: 1.5 }}
                    animate={{ scale: 1 }}
                    className="text-lg font-semibold"
                >
                  {finalPrice <= 0 ? 0 : finalPrice} ₸
                </motion.span>
              </div>

              <div className="flex justify-between items-center mb-2">
                <span className="text-sm text-gray-600">{selectedCount} товар на сумму</span>
                <span className="text-sm text-gray-600">{totalPrice} ₸</span>
              </div>

              <div className="flex justify-between items-center mb-4">
                <span className="text-sm text-gray-600">Скидка по акции</span>
                <span className="text-sm text-gray-600">−13000 ₸</span>
              </div>

              <motion.button
                  whileHover={{ backgroundColor: '' }}
                  whileTap={{ scale: 0.95 }}
                  className="w-full bg-black text-white py-3 rounded-lg text-md font-bold hover:text-gray-300 transition-colors duration-300"
                  onClick={() => {
                    setShowSelectedOnly(true);
                    setIsFullBlockVisible(true);
                  }}
              >
                Перейти к оформлению
              </motion.button>
            </motion.div>

            <AnimatePresence>
              {!isFullBlockVisible && (
                  <motion.div
                      initial={{ opacity: 0, y: 50 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5 }}
                      className="fixed bottom-20 lg:bottom-10 left-0 right-0 flex justify-center xl:hidden"
                  >
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className="bg-black text-white py-3 px-6 rounded-lg text-md font-bold w-[90%] mobile-only"
                        onClick={() => setIsFullBlockVisible(true)}
                    >
                      Перейти к оформлению
                    </motion.button>
                  </motion.div>
              )}
            </AnimatePresence>

            <AnimatePresence>
              {isFullBlockVisible && (
                  <FullBlock onClose={() => {
                    setIsFullBlockVisible(false);
                    setShowSelectedOnly(false);
                  }} />
              )}
            </AnimatePresence>
          </div>
        </div>
      </motion.div>
  );
};

export default Basket;