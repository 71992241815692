import React from 'react';

const Bulavki = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="27.0314mm" height="19.6377mm" viewBox="0 0 71.77 52.14" className={"size-5"}>
        <path className="fill-current stroke-current stroke-2.16 stroke-black" d="M21.98 31.37l-2.23-0.02-0.06-0.02c0.08 0.19 0.27 3.47 0.67 5.11 0.18 0.74 0.47 1.58 0.73 2.21 0.14 0.34 0.28 0.66 0.42 0.98 0.79 1.76 1.55 3.09 2.83 4.59 0.28 0.33 0.31 0.45 0.63 0.77 1.43 1.44 2.23 2.17 3.96 3.32 0.35 0.23 0.48 0.29 0.89 0.51 6.61 3.62 14.45 3.58 20.51-0.4 2.7-1.77 2.78-2.13 4.68-4.14l1.87-2.61c2.12-3.49 2.86-7.6 2.86-11.7 0-3.91 0-7.83 0-11.74l-2.24-0.02c0 3.5 0 7 0 10.49 0 5.28-0.41 8.71-3.08 12.74-0.18 0.27-0.3 0.47-0.53 0.73-0.77 0.89-0.97 1.3-1.89 2.17-2.3 2.19-5.5 3.9-8.64 4.52-2.57 0.51-4.61 0.53-7.19 0.02-2.77-0.54-5.08-1.7-7.3-3.34-0.38-0.28-0.36-0.28-0.67-0.59-0.42-0.4-0.54-0.34-1.34-1.18-1.91-2-3.62-5.08-4.29-7.75-0.25-1.01-0.76-3.51-0.59-4.67z" />
        <polygon className="fill-current stroke-2.16 stroke-black" points="36.48 14.56 36.48 16.8 56.09 16.8 56.09 18.2 57.49 18.21 59.73 18.22 68.93 18.2 68.93 13.16 55.81 13.16 56.09 13.16 56.09 14.56" />
        <path className="fill-current" d="M17.23 6.97l7.35 0.07 5.03 5-0.01 7.28-4.99 5.05-7.38 0.02-0.6-0.75c-0.7-0.68-3.91-4.08-4.48-4.36l0-7.2 2.59-2.48c0.51-0.5 2.18-2 2.49-2.63zm10.09-6.97l-12.78 0c-0.24 0.11-0.29 0.16-0.49 0.38l-8.78 8.84 0.01 5.35 0 2.24-0.01 5.35 4.52 4.56c0.78 0.78 1.46 1.46 2.24 2.24l1.68 1.68c0.21 0.22 0.65 0.73 0.84 0.73l5.15-0.04c0.17 0.06 1.6-0.32 2.29 0.05l5.33 0 9.21-9.21-0.05-5.35 0-2.24 0.01-5.32-9.18-9.24z" />
        <polygon className="fill-current" points="68.93 20.92 71.77 20.92 71.77 10.45 68.93 10.45" />
        <path className="fill-current" d="M21.98 31.37c-0.41-0.22-0.68-0.14-1.17-0.14l-0.95 0.07c-0.02-0.04-0.07 0.03-0.11 0.04l2.23 0.02z" />
        <polygon className="fill-current" points="5.28 16.8 5.28 14.56" />
        <path className="fill-current" d="M2.16 13.49l3.08 0 0 4.35-3.08 0c-1.19 0-2.16-0.97-2.16-2.16l0-0.04c0-1.19 0.97-2.16 2.16-2.16z" />
        <polygon className="fill-current" points="23.24 10.84 24.76 12.76 26.29 14.68 25.74 17.07 25.19 19.46 22.98 20.53 20.77 21.59 18.56 20.52 16.35 19.45 15.81 17.06 15.27 14.67 16.8 12.75 18.33 10.84 20.79 10.84" />
    </svg>
);

export default Bulavki;
