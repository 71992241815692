import React from 'react';
import {useLocation} from "react-router-dom";

const Loader = () => {
    const location = useLocation();
    return (
        <div className={`fixed inset-0 flex items-center justify-center bg-white ${location.pathname === '/products' ? 'bg-opacity-75' : '' }  z-50`}>
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
        </div>
    );
};

export default Loader;
