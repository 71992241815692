// import axios from 'axios';
//
// const axiosClient = axios.create({
//     baseURL: 'http://127.0.0.1:8000/api',
// });
//
// axiosClient.interceptors.request.use(config => {
//     const user = JSON.parse(localStorage.getItem('user'));
//     if (user) {
//         config.params = {
//             ...config.params,
//             user_id: user.id,
//         };
//     }
//     return config;
// }, error => {
//     return Promise.reject(error);
// });
//
// axiosClient.interceptors.response.use(response => {
//     return response;
// }, error => {
//     return Promise.reject(error);
// });
//
// export default axiosClient;



import axios from 'axios';

const axiosClient = axios.create({
    baseURL: 'http://176.124.87.206:9994/api',
    // baseURL: 'http://10.10.3.139:8000/api',
});

axiosClient.interceptors.request.use(config => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
        config.params = {
            ...config.params,
            user_id: user.id,
        };
        config.headers['Authorization'] = user.token;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

axiosClient.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response) {
        if (error.response.status === 401) {
            localStorage.removeItem('user');
            window.location = '/login';
        }
    } else if (error.request) {
        console.log('No response received:', error.request);
    } else {
        console.log('Error', error.message);
    }
    return Promise.reject(error);
});

export default axiosClient;
