import React from 'react';

const Marry = () => (
    <svg
        width="19.9343mm"
        height="19.9143mm"
        className={"size-5"}
        version="1.1"
        style={{ shapeRendering: "geometricPrecision", textRendering: "geometricPrecision", imageRendering: "optimizeQuality", fillRule: "evenodd", clipRule: "evenodd" }}
        viewBox="0 0 39.31 39.27"
    >
        <g id="Слой_x0020_1">
            <g id="_2821856043136">
                <path className="fill-current text-black" d="M4.65 24.54c0,1.57 0.35,2.8 0.91,4.14 1.47,3.52 5.3,5.97 9.2,5.97 1.17,0 2.33,-0.26 3.23,-0.56 1.7,-0.57 3.4,-1.69 4.5,-3.08 6.85,-8.66 -2.37,-19.89 -11.87,-15.66 -1.73,0.77 -3.14,1.96 -4.14,3.44 -0.99,1.46 -1.83,3.35 -1.83,5.75zm24.81 -0.71c0,2.99 -0.15,4.2 -1.34,6.88l-0.45 0.89c-0.29,0.57 -0.71,1.2 -1.08,1.69 -1.26,1.69 -2.52,2.76 -4.26,3.88 -0.65,0.42 -1.16,0.62 -1.83,0.94 -2.76,1.3 -6.24,1.44 -9.17,0.77 -7.05,-1.61 -12.01,-8.6 -11.26,-15.74 0.18,-1.67 0.58,-2.98 1.12,-4.33 0.75,-1.89 1.89,-3.4 3.28,-4.78 2.3,-2.31 6.14,-4.17 9.5,-4.17 2.74,0 4.64,0.18 7.06,1.4 1.21,0.61 2.14,1.14 3.19,2.03l1.37 1.32c0.16,0.2 0.24,0.26 0.41,0.46 0.14,0.16 0.25,0.34 0.38,0.49 1.72,2.25 3.08,5.33 3.08,8.3z" />
                <path className="fill-current text-black" d="M30.25 28.26c0.45,-1.95 0.39,-2.72 0.39,-4.66 0,-0.44 -0.08,-0.47 -0.08,-0.87 3.61,-2.42 5.18,-8.17 3.11,-12.27l-0.9 -1.55c-0.36,-0.55 -0.7,-0.9 -1.17,-1.36 -1.52,-1.52 -4.05,-2.9 -7.05,-2.9 -2.05,0 -4.34,0.79 -5.71,1.79 -0.32,0.23 -0.56,0.4 -0.86,0.64 -0.26,0.21 -0.51,0.49 -0.76,0.74 -0.16,0.15 -0.21,0.21 -0.34,0.37 -0.15,0.2 -0.25,0.23 -0.31,0.48 -2.4,0 -2.63,-0.12 -4.94,0.27 -0.25,0.04 -0.44,0.11 -0.67,0.13 0.35,-1.49 2.7,-4.48 3.9,-5.42 2.03,-1.58 3.38,-2.51 6.13,-3.19 7.89,-1.97 15.28,2.85 17.51,9.59 0.36,1.09 0.75,2.52 0.74,3.92 -0,0.44 0.09,0.69 0.04,1.06 -0.14,1.01 0.09,1.23 -0.4,3.16 -0.84,3.32 -2.41,5.69 -4.91,7.89l-1.36 1.01c-0.55,0.32 -1.81,1.14 -2.37,1.19z" />
                <path className="fill-current text-black" d="M23.69 24.78c-0.05,2.44 -1.25,4.14 -1.34,4.5 -0.59,-0.01 -1.96,-0.4 -2.5,-0.58 -2.81,-0.94 -5.37,-2.85 -7.12,-5.21 -1.01,-1.36 -1.53,-2.47 -2.12,-4.04 -0.18,-0.47 -0.57,-1.96 -0.59,-2.5 0.21,-0.06 0.71,-0.36 0.95,-0.48 0.31,-0.15 0.7,-0.31 1.08,-0.42 0.69,-0.2 1.77,-0.43 2.48,-0.45 0.05,2.1 1.4,4.76 2.9,6.26 1.49,1.49 4.15,2.85 6.26,2.9z" />
            </g>
        </g>
    </svg>
);

export default Marry;
